import { z } from "zod";

const translationPath =
  "page.4uSalesContract.registers.salesContractProducts.errors.";

const salesProductCtrSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "product" }
);

const salesProductCtrPriceListSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "price_list" }
);
export const salesContractProductSchema = z.object({
  id: z.string().optional(),
  salesProductCtr: salesProductCtrSchema,
  salesProductCtrPriceList: salesProductCtrPriceListSchema,
});

export type TSalesContractProductForm = z.infer<
  typeof salesContractProductSchema
>;
