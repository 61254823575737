import { useTranslation } from "react-i18next";

import { Table } from "@4uhub/lib4uhub";
import { Box, Chip, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { memo, useCallback, useState } from "react";
import {
  ICategoriesInternalTemplates,
  ITemplateInternalTemplates,
} from "../../../../../models/internal-templates";
import MainApi from "../../../../../services/mainApi.service";
import Categories from "./CategoriesForm/Categories";
const translationsPath = "chatBot.page.templates.internalCrm.";

const serviceURL =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationInternalTemplateCategory/";
const service = new MainApi<ICategoriesInternalTemplates>(serviceURL);

export const CategoriesPage = memo(() => {
  const [templateInternalForm, setTemplateInternalForm] = useState(false);

  const [selectedId, setSelectedId] = useState<string>();
  const { t } = useTranslation();

  const columns: GridColDef<ICategoriesInternalTemplates>[] = [
    {
      field: "code",
      headerName: t(translationsPath + "code") || "Code",
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "name",
      headerName: t(translationsPath + "name") || "Name",
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "enable",
      headerName: t(translationsPath + "status") || "Status",
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationsPath + "active")
              : t(translationsPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    setTemplateInternalForm(true);
  };

  const handleRowClick = (
    params: GridRowParams<ITemplateInternalTemplates>
  ) => {
    if (!params.row) return;
    setTemplateInternalForm(true);
    setSelectedId(params.row.id);
  };

  const handleBack = useCallback(() => {
    setSelectedId(undefined);
    setTemplateInternalForm(false);
  }, []);
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {templateInternalForm && (
        <Box
          sx={(t) => ({
            p: 1,
            px: 10,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: t.palette.background.default,
            borderRadius: 1,
          })}
        >
          <Categories id={selectedId} onBackButton={handleBack} />
        </Box>
      )}
      {!templateInternalForm && (
        <Table<ICategoriesInternalTemplates>
          searchInput
          showDefaultMacroFilters={false}
          columns={columns}
          service={service}
          onAdd={handleAdd}
          onRowClick={handleRowClick}
          title={t(translationsPath + "categories")}
          defaultPageSize={20}
          pageSizeOptions={[5, 10, 20]}
          addButtonLabel={
            t(translationsPath + "new_category") || "Add Category"
          }
          searchInputPlaceHolder={t(translationsPath + "search_category")}
          confirmOptions={{
            title: t(translationsPath + "confirm.title_category"),
            description: t(translationsPath + "confirm.description_category"),
            confirmationButtonProps: { variant: "contained" },
          }}
        />
      )}
    </Box>
  );
});
