import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import { Grid } from "@mui/material";
import {
  AutoComplete,
  QPFQuickRegisterForm,
  QPJQuickRegisterForm,
  useFormContext,
} from "@4uhub/lib4uhub";
import Checkboxes, { TForm } from "./Checkboxes";
import { listMainGenerics } from "../../../../../../services/generic.service";

const translationPath = "page.4uSalesContract.registers.salesContractPayer.";

const EntitySelect = (): ReactElement => {
  const { t } = useTranslation();
  const { control } = useFormContext<TForm>();
  const entityType = useWatch({ control, name: "entity", defaultValue: "QPF" });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Checkboxes />
        </Grid>
        <Grid item xs={12} sm={12}>
          {entityType === "QPF" && (
            <QPFQuickRegisterForm
              label={t(translationPath + "individual_person")}
              name="individual"
            />
          )}

          {entityType === "QPJ" && (
            <QPJQuickRegisterForm
              label={t(translationPath + "legal_entity")}
              name="legalEntity"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          {entityType === "QPF" && (
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "address_type")}
              name="addressType"
              request={listMainGenerics}
              params={{ Identifier: "IndividualAddressType" }}
            />
          )}
          {entityType === "QPJ" && (
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "address_type")}
              name="addressType"
              request={listMainGenerics}
              params={{ Identifier: "LegalEntityAddressType" }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EntitySelect;
