import {
  AutoComplete,
  Button,
  ISelectType,
  Modal,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Box, Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInstalledExtensions } from "../../../../../../hooks/useInstalledExtensions";
import MainApi from "../../../../../../services/mainApi.service";
import { TNotificationForm } from "../NotificationSchema";

const translationPath = "page.register.notifications.";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

export const NotificationTypeSelect = memo(() => {
  const { t } = useTranslation();

  const [oldValue, setOldValue] = useState<ISelectType | null>(null);

  const [open, setOpen] = useState(false);

  const { setValue, resetField, control } = useFormContext<TNotificationForm>();

  const [notificationTypes, setNotificationTypes] = useState<ISelectType[]>([]);

  const [hasExtensionInstalled] = useInstalledExtensions("42");

  const currentType = useWatch({ name: "type", control });

  const { sendRequest } = useFetch(genericService.list);

  const fetchNotificationTypes = useCallback(async () => {
    const { data, success } = await sendRequest({
      identifier: "NotificationType",
    });
    if (data && success) {
      if (!hasExtensionInstalled) {
        setNotificationTypes(data.filter((type) => type.code !== "3"));
        return;
      }
      setNotificationTypes(data);
    }
  }, [sendRequest, hasExtensionInstalled]);

  const cancelHandler = useCallback(() => {
    if (!oldValue) return;
    setValue("type", oldValue);
  }, [setValue, oldValue]);

  const closeHandler = useCallback(() => {
    setOpen(false);
    cancelHandler();
  }, [cancelHandler]);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const cleanAllHandler = useCallback(() => {
    resetField("type");
    setValue("notificationBatchFilters", []);
  }, [resetField, setValue]);

  const cleanFiltersHandler = useCallback(() => {
    if (!currentType) {
      cleanAllHandler();
      return;
    }
    switch (currentType.code) {
      case "1":
      case "2":
        if (oldValue) {
          if (oldValue.code === "3") {
            // In case of oldValue is equal to 1 or 2 and the current type is equal to 3, clean the filters
            setValue("notificationBatchFilters", []);
          }
        }
        break;
      case "3":
        if (oldValue) {
          if (oldValue.code === "1" || oldValue.code === "2") {
            // In case of oldValue is equal to 1 or 2 and the current type is equal to 3, clean the filters
            setValue("notificationBatchFilters", []);
          }
        }
        break;
      case null:
      case undefined:
        cleanAllHandler();
    }
  }, [currentType, oldValue, setValue, cleanAllHandler]);

  const confirmHandler = useCallback(() => {
    setOldValue(
      currentType
        ? { ...currentType, code: currentType.code ?? undefined }
        : null
    );
    cleanFiltersHandler();
    setOpen(false);
  }, [cleanFiltersHandler, currentType]);

  const valueChangeHandler = useCallback(
    (value: ISelectType | null) => {
      if (!oldValue) {
        setOldValue(value);
        return;
      }
      if (oldValue && !value) {
        openHandler();
        return;
      }
      if (value && oldValue) {
        if (value.id === oldValue.id) return;
      }
      if (!currentType) {
        openHandler();
      } else {
        switch (currentType.code) {
          case "1":
          case "2":
            if (value && value.code === "3") {
              openHandler();
            }
            break;
          case "3":
            if (value && (value.code === "1" || value.code === "2")) {
              openHandler();
            }
            break;
        }
      }
    },
    [oldValue, currentType, openHandler]
  );

  useEffect(() => {
    fetchNotificationTypes();
  }, [fetchNotificationTypes]);

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title="Deseja realmente alterar o tipo da notificação?"
      >
        <Typography
          sx={(t) => ({
            color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
          })}
        >
          Essa ação é irreversível e irá limpar todos os filtros adicionados
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 2,
          }}
        >
          <Button size="small" onClick={closeHandler}>
            Cancelar
          </Button>
          <Button size="small" variant="contained" onClick={confirmHandler}>
            Sim, alterar
          </Button>
        </Box>
      </Modal>
      <Grid item xs={12} sm={6}>
        <AutoComplete
          getOptionLabel={(option) => option.name}
          size="small"
          label={t(translationPath + "type")}
          name="type"
          onValueChange={valueChangeHandler}
          options={notificationTypes}
        />
      </Grid>
    </>
  );
});
