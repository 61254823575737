import { Box, Skeleton } from "@mui/material";
import { memo } from "react";

export const IndividualPhotoLoading = memo(() => {
  return (
    <Box
      sx={{ width: "50%", display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Skeleton variant="rounded" width={200} height={30} />
      <Skeleton variant="rounded" width={"100%"} height={150} />
    </Box>
  );
});
