import { UsePrivileges } from "@4uhub/lib4uhub";
import { Button, Fade, Paper, Popper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "components.extensionInstallPopper.";

const ExtensionInstallPopper = ({
  open,
  anchorEl,
  isRequireSetup,
  onClose,
  onConfirm,
}: {
  open: boolean;
  isRequireSetup: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();

  const { privileges } = UsePrivileges();

  const isMaster = privileges.find(({ name }) => name === "platform-all");

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={"top-end"}
      transition
      style={{ zIndex: 1500 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ p: 2, width: 180 }}>
            <Stack textAlign={"center"} mb={2}>
              <Typography>
                {t(
                  translationPath +
                    `${isRequireSetup && !isMaster ? "request_title" : "title"}`
                )}
              </Typography>
            </Stack>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              gap={1}
            >
              <Button
                size="small"
                fullWidth
                variant="outlined"
                onClick={onClose}
              >
                {t(translationPath + "cancel")}
              </Button>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={onConfirm}
              >
                {t(
                  translationPath +
                    `${isRequireSetup && !isMaster ? "request" : "install"}`
                )}
              </Button>
            </Stack>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default ExtensionInstallPopper;
