import { Grid } from "@mui/material";
import { memo } from "react";
import { WhatsappSection } from "../../../../../components/WhatsappTemplate/WhatsappSection/WhatsappSection";
import { TextField } from "../../../../../components/WhatsappTemplate/TextField/TextField";
import { BodyField } from "../../../../../components/WhatsappTemplate/BodyField/BodyField";
import { useFormContext } from "@4uhub/lib4uhub";
import { useWatch } from "react-hook-form";
import { TWhatsappForm } from "./wathsappSchema";
import { useTranslation } from "react-i18next";

const translationPath = "chatBot.page.templates.whatsapp.";

export const WhatsappBasicData = memo(() => {
  const { t } = useTranslation();

  const { control } = useFormContext<TWhatsappForm>();

  const ticketAutomation = useWatch({
    name: "ticketAutomation",
    control,
  });

  const ticketAutomationInternalTemplate = useWatch({
    name: "ticketAutomationInternalTemplate",
    control,
  });

  return (
    <>
      <Grid item xs={12} sm={12}>
        <WhatsappSection
          title={t(translationPath + "header")}
          subtitle={t(translationPath + "header_sub")}
          chipLabel={`${t(translationPath + "optional")}`}
        >
          <TextField
            name="title"
            label={t(translationPath + "title")}
            maxLength={60}
          />
        </WhatsappSection>
      </Grid>

      <BodyField
        automationId={ticketAutomation ? ticketAutomation.id : ""}
        internalTemplateId={
          ticketAutomationInternalTemplate
            ? ticketAutomationInternalTemplate.id
            : ""
        }
        componentType="BODY"
      />

      <Grid item xs={12} sm={12}>
        <WhatsappSection
          title={t(translationPath + "footer")}
          subtitle={t(translationPath + "footer_sub")}
          chipLabel={`${t(translationPath + "optional")}`}
        >
          <TextField
            name="footer"
            label={t(translationPath + "footer")}
            maxLength={60}
          />
        </WhatsappSection>
      </Grid>
    </>
  );
});
