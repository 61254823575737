import { memo, useCallback, useState } from "react";
import { ISalesContractProps } from "../../SalesContractHistories/SalesContractHistoryForm/models";
import { useParams } from "react-router-dom";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { useTranslation } from "react-i18next";
import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import {
  createSalesContractPayer,
  findSalesContractPayerById,
  updateSalesContractPayer,
} from "../../../../../services/salesContractPayer.service";
import { Grid } from "@mui/material";
import { ISalesContractPayer } from "../../../../../models/sales-contract-payer";
import {
  salesContractPayerSchema,
  TSalesContractPayerForm,
} from "./salesContractPayerSchema";
import { SalesContractPayerForm } from "./SalesContractPayerForm";
import { SalesContractPayerInformation } from "./SalesContractPayerInformation";

const translationPath = "page.4uSalesContract.registers.salesContractPayer.";

export const SalesContractPayer = memo(({ id }: ISalesContractProps) => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();
  const [dataSalesContractPayer, setDataSalesContractPayer] =
    useState<ISalesContractPayer>();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractPayer
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateSalesContractPayer
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    findSalesContractPayerById
  );

  const handleEntity = (data: any, type: "QPF" | "QPJ") => {
    if (!data) return null;

    switch (type) {
      case "QPJ":
        return {
          ...data,
          corporateName: data?.corporateName,
        };
      default:
        return {
          ...data,
          individualName: data?.individualName,
        };
    }
  };

  const updateHandler = (v: TSalesContractPayerForm, id: string) => {
    return update({
      item: {
        id: id,
        payerTitle: v.payerTitle,
        addressTypeId: v.addressType.id,
        integrationId: v.integrationId || "",
        individualId: v.individual ? v.individual.identifier : undefined,
        legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
        salesContractId: salesContractId ?? "",
      },
      id: id,
    });
  };

  const saveHandler = (v: TSalesContractPayerForm) =>
    save({
      payerTitle: v.payerTitle,
      addressTypeId: v.addressType.id,
      integrationId: v.integrationId || "",
      individualId: v.individual ? v.individual.identifier : undefined,
      legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
      salesContractId: salesContractId ?? "",
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesContractPayerForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setDataSalesContractPayer(data);
        const newData: TSalesContractPayerForm = {
          ...data,
          code: data.code ? data.code.toString() : "",
          entity: data.legalEntity ? "QPJ" : "QPF",
          individual: handleEntity(data.individual, "QPF"),
          legalEntity: handleEntity(data.legalEntity, "QPJ"),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;
  return (
    <FormContainer<TSalesContractPayerForm>
      title={t(translationPath + "title")}
      subtitle=""
      getFunction={getHandler}
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      onSuccess={successHandle}
      showBackButton={false}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      loading={loading}
      schema={salesContractPayerSchema}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          {type !== "create" && dataSalesContractPayer?.approvalDate ? (
            <SalesContractPayerInformation data={dataSalesContractPayer} />
          ) : (
            <SalesContractPayerForm
              type={type}
              dataSalesContractPayer={dataSalesContractPayer}
            />
          )}
        </Grid>
      )}
    />
  );
});
