import { FormContainer, FormTabs, Treturn, useFetch } from "@4uhub/lib4uhub";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ModalForm } from "../../../../../components/ModalForm/ModalForm";
import { ISaleChannelPayload } from "../../../../../models/sale-channel";
import {
  createSaleChannel,
  findSaleChannelById,
  updateSaleChannel,
} from "../../../../../services/saleChannel.service";
import { SalesContractProvider } from "../../../../../store/contexts/SalesContractContext";
import SalesSellersPage from "../../SalesSellers/SalesSellersPage";
import Fields from "./Fields/Fields";
import { RenderForms } from "./RenderForms/RenderForms";
import { TSalesChannelForm, salesChannelSchema } from "./salesChannel.schema";
const translationsPath = "page.4uSalesContract.registers.salesChannel.";

const SalesChannel = () => {
  const { id } = useParams();

  const { sendRequest: create, loading: isSaving } =
    useFetch(createSaleChannel);
  const { sendRequest: update, loading: isUpdating } =
    useFetch(updateSaleChannel);
  const { sendRequest: findById, loading: isFetching } =
    useFetch(findSaleChannelById);

  const { t } = useTranslation();
  const translate = (field: string) => t(translationsPath + field);

  const handleFormValues = ({
    salesChannelClassification,
    salesChannelType,
    situation,
    individual,
    legalEntity,
    title,
    ...rest
  }: TSalesChannelForm): ISaleChannelPayload => ({
    ...rest,
    titleChannel: title,
    individualId: individual ? individual.identifier : undefined,
    legalEntityId: legalEntity ? legalEntity.id : undefined,
    salesChannelTypeId: salesChannelType?.id,
    salesChannelClassificationId: salesChannelClassification?.id,
  });

  const handleEntity = (data: any, type: "QPF" | "QPJ") => {
    if (!data) return null;

    switch (type) {
      case "QPJ":
        return {
          ...data,
          corporateName: data?.corporateName,
        };
      default:
        return {
          ...data,
          individualName: data?.individualName,
        };
    }
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesChannelForm>> => {
      const { data, success } = await findById(id);

      if (data && success) {
        return {
          success,
          data: {
            ...data,
            code: data.code?.toString(),
            title: data.titleChannel,
            entity: data.legalEntity ? "QPJ" : "QPF",
            individual: handleEntity(data.individual, "QPF"),
            legalEntity: handleEntity(data.legalEntity, "QPJ"),
          },
        };
      }

      return { data, success };
    },
    [findById]
  );

  const createHandler = (values: TSalesChannelForm) =>
    create(handleFormValues(values));

  const updateHandler = (values: TSalesChannelForm, id: string) =>
    update({ id, item: handleFormValues({ ...values, id }) });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <SalesContractProvider>
      <ModalForm>
        <RenderForms />
      </ModalForm>
      <FormContainer<TSalesChannelForm>
        title={translate("sales_channel")}
        subtitle=""
        getFunction={getHandler}
        saveFunction={createHandler}
        updateFunction={updateHandler}
        loading={isLoading}
        schema={salesChannelSchema}
        fields={(type) => (
          <FormTabs
            tabs={[
              {
                label: translate("tabs.sales_channel"),
                component: <Fields type={type} />,
              },
              {
                label: translate("tabs.seller"),
                component: <SalesSellersPage />,
                disabled: !id,
              },
            ]}
          />
        )}
      />
    </SalesContractProvider>
  );
};

export default SalesChannel;
