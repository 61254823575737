import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { Box, Typography } from "@mui/material";
import { IPhotoLabelProps } from "./models";

const PhotoLabel = ({ label }: IPhotoLabelProps) => {
  return (
    <Box
      sx={(t) => ({
        pr: 3,
        pl: 1,
        py: 1,
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        gap: 2,
        backgroundColor: t.palette.grey[t.palette.mode === "light" ? 100 : 900],
        borderRadius: 10,
      })}
    >
      <Box
        sx={(t) => ({
          p: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: t.palette.background.default,
          borderRadius: 10,
        })}
      >
        <CameraAltRoundedIcon fontSize="small" />
      </Box>
      <Typography variant="subtitle2">{label}</Typography>
    </Box>
  );
};

export default PhotoLabel;
