import { useTranslation } from "react-i18next";

import { Table } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";

import MainApi from "../../../../services/mainApi.service";
import { memo, useCallback, useMemo, useState } from "react";
import { ISalesContractPayerFinantial } from "../../../../models/sales-contract-payer-finantial";
import { SalesContractPayerFinantial } from "./SalesContractPayerFinantialForm/SalesContractPayerFinantial";
const translationsPath =
  "page.4uSalesContract.registers.salesContractPayerFinantial.";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractPayerFinantial/";
const service = new MainApi<ISalesContractPayerFinantial>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractPayerFinantial>;

export const SalesContractPayerFinantialPage = memo(
  ({
    idPayer,
    onSuccess,
    onCancel,
  }: {
    idPayer: string | null;
    onSuccess: () => void;
    onCancel: () => void;
  }) => {
    const [
      salesContractPayerFinantialForm,
      setsalesContractPayerFinantialForm,
    ] = useState(false);

    const [selectedId, setSelectedId] = useState<string>();

    const {
      t,
      i18n: { language },
    } = useTranslation();
    const translate = (field: string) => t(translationsPath + field);

    const columns: GridColDef<ISalesContractPayerFinantial>[] = [
      {
        field: "initialDate",
        headerName: translate("initial_date"),
        renderCell: (params) => {
          if (!params.value) return "-";
          const date = new Date(params.value);
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          return format(date, "P", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
      {
        field: "endDate",
        headerName: translate("end_date"),
        renderCell: (params) => {
          if (!params.value) return "-";
          const date = new Date(params.value);
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          return format(date, "P", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
      {
        field: "bilingMethod",
        headerName: translate("biling_method"),
        renderCell: (params) => (
          <Typography variant="body2">
            {params.row.bilingMethod?.name}
          </Typography>
        ),
      },
      {
        field: "monthlyPaymentDueDate",
        headerName: translate("monthly_payment_due_date"),
        renderCell: ({ value }) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
      {
        field: "phone",
        headerName: translate("phone"),
        renderCell: ({ value }) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
      {
        field: "mail",
        headerName: translate("mail"),
        renderCell: ({ value }) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
    ];

    const handleAdd = () => {
      setsalesContractPayerFinantialForm(true);
    };

    const handleRowClick = (
      params: GridRowParams<ISalesContractPayerFinantial>
    ) => {
      if (!params.row) return;
      setsalesContractPayerFinantialForm(true);
      setSelectedId(params.row.id);
    };

    const handleBack = useCallback(() => {
      setSelectedId(undefined);
      setsalesContractPayerFinantialForm(false);
    }, []);

    const props = useMemo(() => {
      return { SalesContractPayerId: idPayer };
    }, [idPayer]);

    return (
      <Box sx={{ height: 600, width: "100%" }}>
        {salesContractPayerFinantialForm && (
          <Box
            sx={(t) => ({
              p: 1,
              px: 10,
              width: "100%",
              height: "100%",
              overflow: "auto",
              backgroundColor: t.palette.background.default,
              borderRadius: 1,
            })}
          >
            <SalesContractPayerFinantial
              id={selectedId}
              idPayer={idPayer || ""}
              onBackButton={handleBack}
            />
          </Box>
        )}
        <Box height={600}>
          <Table<ISalesContractPayerFinantial>
            searchInput
            showDefaultMacroFilters={false}
            columns={columns}
            service={service}
            onAdd={handleAdd}
            onRowClick={handleRowClick}
            serviceProps={props}
            title={translate("title")}
            defaultPageSize={20}
            pageSizeOptions={[5, 10, 20]}
            addButtonLabel={translate("add")}
            searchInputPlaceHolder={translate("search")}
            confirmOptions={{
              title: translate("confirm.title"),
              description: translate("confirm.description"),
              confirmationButtonProps: { variant: "contained" },
            }}
          />
        </Box>
      </Box>
    );
  }
);
