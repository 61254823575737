import { AutoCompleteUncontrolled, ISelectType, Table } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Chip, Stack } from "@mui/material";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { IIndividualValidations } from "../../../models/individual-validations";
import { IIndividual } from "../../../models/individuals";
import MainApiService from "../../../services/mainApi.service";

const TRANSLATION_PATH = "page.register.individualValidation.";

const INDIVIDUALS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/IndividualValidation/";
const individualValidationService = new MainApiService<IIndividualValidations>(
  INDIVIDUALS_ROUTE
);

const IndividualValidationsPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [status, setStatus] = useState<ISelectType | null>({
    id: "0",
    name: t(`${TRANSLATION_PATH}all`),
  });

  const navigate = useNavigate();

  const columns: GridColDef<IIndividualValidations>[] = useMemo(
    () => [
      {
        field: "individual",
        headerName: t(TRANSLATION_PATH + "name") || "Name",
        renderCell: (params) => {
          return params.value ? params.value.name : "-";
        },
      },
      {
        field: "created",
        headerName: t(TRANSLATION_PATH + "created") || "Created Date",
        maxWidth: 200,
        renderCell: (params) => {
          if (!params.value) return "-";

          const initialDate = new Date(params.value);
          return format(initialDate, "Pp", {
            locale:
              language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
          }).replaceAll(",", "");
        },
      },
      {
        field: "status",
        headerName: t(TRANSLATION_PATH + "status") || "Status",
        maxWidth: 250,
        renderCell: (params) => {
          if (!params.value) return "-";

          const code = params.value.code;

          return (
            <Chip
              label={
                code === "2"
                  ? t(`${TRANSLATION_PATH}approved`)
                  : code === "3"
                  ? t(`${TRANSLATION_PATH}disapproved`)
                  : t(`${TRANSLATION_PATH}pending`)
              }
              color={
                code === "2" ? "success" : code === "3" ? "error" : "default"
              }
            />
          );
        },
      },
    ],
    [t, language]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams<IIndividual>) => {
      navigate(`validate/${String(params.row.id)}`);
    },
    [navigate]
  );

  const handleAdd = useCallback(() => {
    navigate("validate");
  }, [navigate]);

  const props = useMemo(() => {
    return {
      StatusCode: status && status.id !== "0" ? status.id : undefined,
    };
  }, [status]);

  return (
    <Table<IIndividualValidations>
      showDefaultMacroFilters={false}
      service={individualValidationService}
      columns={columns}
      title={
        t(TRANSLATION_PATH + "individual_validations") ||
        "Registration Validation PF"
      }
      searchInput
      defaultActions={false}
      showDeleteButton={false}
      onRowClick={handleRowClick}
      onAdd={handleAdd}
      addButtonLabel={
        t(TRANSLATION_PATH + "approve_registrations") || "Registration Approval"
      }
      addButtonIcon={CheckCircleRoundedIcon}
      serviceProps={props}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      macroFilters={() => (
        <Stack sx={{ minWidth: 250 }}>
          <AutoCompleteUncontrolled
            value={status}
            label={t(TRANSLATION_PATH + "status") || "Status"}
            onValueSelected={setStatus}
            size="small"
            fullWidth
            clearable={false}
            options={[
              {
                id: "0",
                name: t(TRANSLATION_PATH + "all") || "All",
              },
              {
                id: "1",
                name: t(TRANSLATION_PATH + "pending") || "Pending",
              },
              {
                id: "2",
                name: t(TRANSLATION_PATH + "approved") || "Approved",
              },
              {
                id: "3",
                name: t(TRANSLATION_PATH + "disapproved") || "Disapproved",
              },
            ]}
          />
        </Stack>
      )}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search") || "Search for a registration"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default IndividualValidationsPage;
