import { z } from "zod";
const translationPath =
  "page.4uSalesContract.registers.salesContractAttachments.errors.";

const genericSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
});

const salesContractAttachmentTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.number().optional().nullish(),
  },
  { invalid_type_error: translationPath + "attachment_type" }
);
const mediaFileSchema = z.object(
  {
    mediaType: genericSchema.optional(),
    id: z.string().min(1, translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
    file: z.instanceof(File).optional(),
  },
  { required_error: translationPath + "file" }
);

export const salesContractAttachmentSchema = z.object({
  id: z.string().optional(),
  title: z.string().min(1, translationPath + "title"),
  description: z.string().min(1, translationPath + "description"),
  attachmentDate: z.date({
    invalid_type_error: translationPath + "attachment_date",
  }),
  salesContractAttachmentType: salesContractAttachmentTypeSchema,
  mediaFile: mediaFileSchema,
});

export type TSalesContractAttachmentForm = z.infer<
  typeof salesContractAttachmentSchema
>;
