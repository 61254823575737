import { GlobalAxios } from "../axios/axios-config";
import {
  ICategoriesInternalTemplates,
  INewCategoriesInternalTemplates,
} from "../models/internal-templates";

const TICKET_AUTO_INTERNAL_TEMPLATE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationInternalTemplateCategory";

export const createCategoriesInternalTemplates = (
  item: INewCategoriesInternalTemplates
) => {
  return GlobalAxios.post(TICKET_AUTO_INTERNAL_TEMPLATE, item);
};

export const getCategoriesInternalTemplatesById = (id: string) => {
  return GlobalAxios.get<ICategoriesInternalTemplates>(
    TICKET_AUTO_INTERNAL_TEMPLATE + "/" + id
  );
};

export const updateCategoriesInternalTemplatesById = ({
  item,
  id,
}: {
  item: INewCategoriesInternalTemplates;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_AUTO_INTERNAL_TEMPLATE + "/" + id, item);
};

export const listCategoriesInternalTemplates = (params?: any) => {
  return GlobalAxios.get<ICategoriesInternalTemplates[]>(
    TICKET_AUTO_INTERNAL_TEMPLATE + "/list",
    {
      params: params,
    }
  );
};
