import { z } from "zod";

const translationPath = "chatBot.page.templates.internalCrm.errors.";
export const ticketAutomationInternalTemplateCategorySchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
});

export type TTicketAutomationInternalTemplateCategoryForm = z.infer<
  typeof ticketAutomationInternalTemplateCategorySchema
>;
