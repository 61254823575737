import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";

import { listCategoriesInternalTemplates } from "../../../../../../services/ticketAutomationInternalTemplateCategory.service";

const translationPath = "chatBot.page.templates.internalCrm.";
const AutoCompleteCategories = () => {
  const { t } = useTranslation();

  return (
    <AutoComplete
      size="small"
      name="category"
      label={t(translationPath + "categories")}
      getOptionLabel={({ name }) => name}
      request={listCategoriesInternalTemplates}
    />
  );
};

export default AutoCompleteCategories;
