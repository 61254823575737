import {
  MainApiService,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { Divider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalAxios } from "../../../../axios/axios-config";
import { IndividualData } from "../../../../components/IndividualsValidations/IndividualData/IndividualData";
import { IndividualPhotos } from "../../../../components/IndividualsValidations/IndividualPhotos/IndividualPhotos";
import { IndividualValidationContainer } from "../../../../components/IndividualsValidations/IndividualValidationContainer/IndividualValidationContainer";
import { IndividualValidationLoading } from "../../../../components/IndividualsValidations/IndividualValidationLoading/IndividualValidationLoading";
import { NotFound } from "../../../../components/IndividualsValidations/NotFound/NotFound";
import {
  IIndividualValidation,
  IIndividualValidations,
} from "../../../../models/individual-validations";
import { getIndividualValidationById } from "../../../../services/individualValidation.service";

const TRANSLATION_PATH = "page.register.individualValidation.";

const INDIVIDUALS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/IndividualValidation/";
const individualValidationService = new MainApiService<IIndividualValidations>(
  INDIVIDUALS_ROUTE,
  GlobalAxios
);

const IndividualValidation = () => {
  const { id } = useParams();

  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const navigate = useNavigate();

  const [individual, setIndividual] = useState<IIndividualValidation | null>(
    null
  );

  const [currentId, setCurrentId] = useState<string | null>(null);

  const { sendRequest, loading } = useFetch(getIndividualValidationById);

  const { sendRequest: getValidations, loading: loadingValidations } = useFetch(
    individualValidationService.getAll
  );

  const fetchAllValidations = useCallback(async () => {
    const { data, success } = await getValidations({
      PageNumber: 1,
      PageSize: 1,
      ...{ StatusCode: "1" },
    });
    if (data && success) {
      if (data.items.length > 0) {
        return setCurrentId(data.items[0].id);
      }
      setMessage({
        message: `${t(`${TRANSLATION_PATH}no_registrations`)}`,
        type: "info",
      });
      navigate(-1);
      setCurrentId(null);
    }
  }, [getValidations, navigate, setMessage, t]);

  const fetchIndividualValidation = useCallback(async () => {
    if (!id && !currentId) return;
    const { data, success } = await sendRequest(id ?? currentId ?? "");
    if (data && success) {
      setIndividual(data);
    }
  }, [currentId, id, sendRequest]);

  useEffect(() => {
    fetchIndividualValidation();
  }, [fetchIndividualValidation]);

  useEffect(() => {
    if (id) return;
    fetchAllValidations();
  }, [fetchAllValidations, id]);

  if (loading || loadingValidations) {
    return <IndividualValidationLoading />;
  }

  if (!individual) {
    return <NotFound />;
  }

  return (
    <IndividualValidationContainer>
      <>
        <IndividualData
          individualValidation={individual}
          onNext={fetchAllValidations}
        />
        <Divider sx={{ mx: 2 }} />
        <IndividualPhotos photos={individual.individualValidationDocs} />
      </>
    </IndividualValidationContainer>
  );
};

export default IndividualValidation;
