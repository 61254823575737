import { useCallback, useLayoutEffect, useState } from "react";
import { FormContainer, ISelectType, Treturn, useFetch } from "@4uhub/lib4uhub";
import { TButtonsList } from "../../../../../store/contexts/TemplateWhatsContext";
import { TWhatsappForm, whatsappSchema } from "./wathsappSchema";
import {
  createWhatsappTemplate,
  getWhatsappTemplateById,
  updateWhatsappTemplateById,
} from "../../../../../services/whatsappTemplate.service";
import { useTranslation } from "react-i18next";
import { WhatsappFields } from "./WhatsappFields";
import { BUTTONS_OPTIONS } from "../../../../../components/WhatsappTemplate/Buttons/SelectButtons/SelectButtons";
import { countriesList } from "../../../../../services/country.service";
import { ISelectCountry } from "../../../../../components/UI/Inputs/AutoCompleteCountry/models";

const translationPath = "chatBot.page.templates.whatsapp.";

const Whatsapp = () => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<ISelectType>();

  const [buttonsOrder, setButtonsOrder] = useState<TButtonsList[]>([]);

  const [countries, setCountries] = useState<ISelectCountry[]>([]);

  const [body, setBody] = useState("");

  const { sendRequest } = useFetch(countriesList);

  const fetchCountries = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setCountries(data);
    }
  }, [sendRequest]);

  const getButtonsOrder = useCallback((list: TButtonsList[]) => {
    setButtonsOrder(list);
  }, []);

  const { sendRequest: save, loading: sLoading } = useFetch(
    createWhatsappTemplate
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateWhatsappTemplateById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getWhatsappTemplateById
  );

  const saveTemplateJson = useCallback(
    (v: TWhatsappForm) => {
      const components = [];

      if (v.title && v.title.trim() !== "") {
        components.push({
          type: "HEADER",
          format: "TEXT",
          text: v.title,
        });
      }

      components.push({
        type: "BODY",
        text: v.body,
        example:
          v.bodyExamples && v.bodyExamples.length > 0
            ? {
                body_text: [
                  v.bodyExamples
                    ? v.bodyExamples.map((v) => v.example)
                    : undefined,
                ],
              }
            : undefined,
      });

      if (v.footer && v.footer.trim() !== "") {
        components.push({
          type: "FOOTER",
          text: v.footer,
        });
      }

      const buttons =
        buttonsOrder[0] === "1"
          ? [
              ...(v.ctaButtons
                ? v.ctaButtons
                    .sort((a, b) =>
                      a.order < b.order ? -1 : a.order > b.order ? 1 : 0
                    )
                    .map((btn) => ({
                      type: btn.actionType.value,
                      text: btn.actionType.id === "5" ? undefined : btn.text,
                      url: btn.actionType.id === "2" ? btn.payload : undefined,
                      example:
                        btn.urlType?.id === "2"
                          ? [btn.payload?.match(/\{\{(.+?)\}\}/)?.[1] || ""]
                          : btn.actionType.id === "5"
                          ? btn.payload
                          : undefined,
                      phone_number:
                        btn.actionType.id === "3"
                          ? `+${btn.country?.ddiCode}${btn.payload
                              ?.replaceAll("(", "")
                              ?.replaceAll(")", "")
                              ?.replaceAll("-", "")}`
                          : undefined,
                    }))
                : []),
              ...(v.customButtons
                ? v.customButtons
                    .sort((a, b) =>
                      a.order < b.order ? -1 : a.order > b.order ? 1 : 0
                    )
                    .map((btn) => ({
                      type: "QUICK_REPLY",
                      text: btn.text,
                    }))
                : []),
            ]
          : [
              ...(v.customButtons
                ? v.customButtons
                    .sort((a, b) =>
                      a.order < b.order ? -1 : a.order > b.order ? 1 : 0
                    )
                    .map((btn) => ({
                      type: "QUICK_REPLY",
                      text: btn.text,
                    }))
                : []),
              ...(v.ctaButtons
                ? v.ctaButtons
                    .sort((a, b) =>
                      a.order < b.order ? -1 : a.order > b.order ? 1 : 0
                    )
                    .map((btn) => ({
                      type: btn.actionType.value,
                      text: btn.actionType.id === "5" ? undefined : btn.text,
                      url: btn.actionType.id === "2" ? btn.payload : undefined,
                      example:
                        btn.urlType?.id === "2"
                          ? [btn.payload?.match(/\{\{(.+?)\}\}/)?.[1] || ""]
                          : btn.actionType.id === "5"
                          ? btn.payload
                          : undefined,
                      phone_number:
                        btn.actionType.id === "3"
                          ? `+${btn.country?.ddiCode}${btn.payload
                              ?.replaceAll("(", "")
                              ?.replaceAll(")", "")
                              ?.replaceAll("-", "")}`
                          : undefined,
                    }))
                : []),
            ];

      if (buttons && buttons.length > 0) {
        components.push({
          type: "BUTTONS",
          buttons: buttons,
        });
      }

      const templateInJson = JSON.stringify({
        name: "",
        language: "pt_BR",
        category: "UTILITY",
        allow_category_change: true,
        components: components,
      });

      return templateInJson;
    },
    [buttonsOrder]
  );

  const saveHandler = (v: TWhatsappForm) => {
    return save({
      ticketServiceId: v.ticketService.id,
      ticketAutomationId: v.ticketAutomation.id,
      ticketAutomationInternalTemplateId: v.ticketAutomationInternalTemplate
        ? v.ticketAutomationInternalTemplate.id
        : null,
      name: v.name,
      enable: v.enable,
      templateJson: saveTemplateJson(v),
    });
  };

  const updateHandler = (v: TWhatsappForm, id: string) => {
    return update({
      id: id,
      item: {
        id: id,
        name: v.name,
        enable: v.enable,
        ticketAutomationInternalTemplateId: v.ticketAutomationInternalTemplate
          ? v.ticketAutomationInternalTemplate.id
          : null,
        templateJson: saveTemplateJson(v),
      },
    });
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TWhatsappForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setStatus(data.status);

        const templateJson =
          data.ticketAutomationTemplateMetas.length > 0
            ? JSON.parse(
                data.ticketAutomationTemplateMetas[
                  data.ticketAutomationTemplateMetas.length - 1
                ].content
              )
            : [];

        const components: any[] =
          "components" in templateJson ? templateJson.components : [];

        const buttons = components.find((c) => c.type === "BUTTONS")?.buttons;

        const body = components.find((c) => c.type === "BODY");

        setBody(body.text || "");

        if (buttons && buttons.length > 0 && buttons[0].type === "QUICK_REPLY")
          setButtonsOrder(["2", "1"]);

        const newData: TWhatsappForm = {
          ...data,
          title: components.find((c) => c.type === "HEADER")?.text || "",
          body: body ? body.text : "",
          bodyExamples:
            body && body.example && body.example.body_text
              ? body.example.body_text[0].map((e: string) => ({ example: e }))
              : [],
          footer: components.find((c) => c.type === "FOOTER")?.text || "",
          customButtons:
            buttons &&
            buttons
              .filter((btn: any) => btn.type === "QUICK_REPLY")
              .map((btn: any, index: number) => ({
                id: index.toString(),
                order: index,
                text: btn.text,
              })),
          ctaButtons:
            (buttons &&
              buttons
                .filter((b: any) => b.type !== "QUICK_REPLY")
                .map((btn: any, index: number) => {
                  const country = btn.phone_number
                    ? countries.find(
                        (country) =>
                          country.ddiCode === btn.phone_number?.substring(1, 3)
                      )
                    : null;

                  const button = {
                    id: index.toString(),
                    order: index,
                    text: btn.text,
                    actionType: BUTTONS_OPTIONS.find(
                      (o) => o.value === btn.type
                    ),
                    payload:
                      btn.url ||
                      btn.example ||
                      (btn.phone_number ? btn.phone_number.substring(3) : "") ||
                      "",
                  };

                  return country ? { ...button, country } : button;
                })) ||
            undefined,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get, countries]
  );

  const loading = sLoading || uLoading || gLoading;

  useLayoutEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  return (
    <FormContainer<TWhatsappForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      disableSave={status && status.code === "2"}
      title={t(translationPath + "whatsapp_template")}
      schema={whatsappSchema}
      subtitle={t(translationPath + "new_whatsapp_template")}
      subtitleWatchField={"name"}
      fields={() => (
        <WhatsappFields
          status={status}
          buttonsOrder={buttonsOrder}
          body={body}
          onGetButtonsOrder={getButtonsOrder}
        />
      )}
    />
  );
};

export default Whatsapp;
