import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  INewTemplateInternalTemplates,
  ITemplateInternalTemplates,
} from "../models/internal-templates";

const TICKET_AUTO_INTERNAL_TEMPLATE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationInternalTemplate";

export const createTemplateInternalTemplates = (
  item: INewTemplateInternalTemplates
) => {
  return GlobalAxios.post(TICKET_AUTO_INTERNAL_TEMPLATE, item);
};

export const getTemplateInternalTemplatesById = (id: string) => {
  return GlobalAxios.get<ITemplateInternalTemplates>(
    TICKET_AUTO_INTERNAL_TEMPLATE + "/" + id
  );
};

export const updateTemplateInternalTemplatesById = ({
  item,
  id,
}: {
  item: INewTemplateInternalTemplates;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_AUTO_INTERNAL_TEMPLATE + "/" + id, item);
};

export const listTemplatesInternalTemplates = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(
    TICKET_AUTO_INTERNAL_TEMPLATE + "/list",
    {
      params: params,
    }
  );
};
