import { Box, Skeleton } from "@mui/material";
import { memo } from "react";

export const IndividualDataLoading = memo(() => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Skeleton variant="rounded" width={60} height={10} />
      <Skeleton variant="rounded" width={120} height={15} />
    </Box>
  );
});
