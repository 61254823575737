import {
  IIndividualList,
  QPFQuickRegisterForm,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { TSalesSellerForm } from "../salesSeller.schema";
import { useTranslation } from "react-i18next";

export const SalesSellerIndividual = memo(() => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<TSalesSellerForm>();

  const changeTitleSeller = useCallback(
    async (value: IIndividualList) => {
      if (!value) return;
      setValue("titleSeller", value.individualName);
    },
    [setValue]
  );

  return (
    <Grid item xs={12} sm={12}>
      <QPFQuickRegisterForm
        label={t("page.register.users.individual") || "Individual"}
        name="individual"
        onValueChange={changeTitleSeller}
      />
    </Grid>
  );
});
