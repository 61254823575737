import { Card, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IAvailableFor } from "../../models/extensions-store";
import ExtensionDetailInstall from "./ExtensionDetailInstall";

const translationPath = "components.extensionsStoreDetail.";

const ExtensionDetailAvailable = ({
  extensionAv,
  extensionId,
  isDrawerOpen,
  isRequireSetup,
  onRealoadExtensionsList,
  toggleDetail,
  onToggleExtensionsOption,
}: {
  extensionAv: IAvailableFor[];
  extensionId: string;
  isDrawerOpen: boolean;
  isRequireSetup: boolean;
  onRealoadExtensionsList: () => void;
  toggleDetail: (open: boolean) => void;
  onToggleExtensionsOption: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={12} mt={2}>
      <Typography
        variant="subtitle1"
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
        })}
      >
        {t(translationPath + "available_for")}:
      </Typography>
      <Card
        variant="outlined"
        sx={(theme) => ({
          mt: 2,
          maxHeight: 208,
          overflowY: "auto",
          backgroundColor:
            theme.palette.mode === "light" ? "#fff" : theme.palette.grey[900],
        })}
      >
        {extensionAv.map((available, index) => (
          <Stack
            p={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={
              index !== extensionAv.length - 1 ? "1px solid #ccc" : undefined
            }
            key={available.siteApp.id}
          >
            <Typography variant="subtitle2">
              {available.siteApp.name}
            </Typography>
            <ExtensionDetailInstall
              extensionId={extensionId}
              isRequireSetup={isRequireSetup}
              siteAppId={available.siteApp.id}
              isDrawerOpen={isDrawerOpen}
              onRealoadExtensionsList={onRealoadExtensionsList}
              onToggleExtensionsOption={onToggleExtensionsOption}
              toggleDetail={toggleDetail}
            />
          </Stack>
        ))}
      </Card>
    </Grid>
  );
};

export default ExtensionDetailAvailable;
