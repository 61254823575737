import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IExtension } from "../../models/extensions-store";
import Drawer from "../Drawer";
import ExtensionDetailAbout from "./ExtensionDetailAbout";
import ExtensionDetailAvailable from "./ExtensionDetailAvailable";
import ExtensionDetailConfig from "./ExtensionDetailConfig";
import ExtensionDetailDependent from "./ExtensionDetailDependent";
import ExtensionDetailHeader from "./ExtensionDetailHeader";

interface IExtensionStoreDetail {
  open: boolean;
  extension: IExtension;
  toggleDetail: (open: boolean) => void;
  onRealoadExtensionsList: () => void;
  onToggleExtensionsOption: () => void;
}

const translationPath = "components.extensionsStoreDetail.";

const ExtensionsStoreDetail: React.FC<IExtensionStoreDetail> = ({
  open,
  extension,
  toggleDetail,
  onRealoadExtensionsList,
  onToggleExtensionsOption,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer anchor={"right"} open={open} toggleDetail={toggleDetail}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">{t(translationPath + "title")}</Typography>
      </Grid>
      <ExtensionDetailHeader extension={extension} />
      <ExtensionDetailAbout description={extension.description} />
      {extension.availableFor.length !== 0 && (
        <ExtensionDetailAvailable
          isDrawerOpen={open}
          isRequireSetup={extension.isRequiredSetup}
          extensionAv={extension.availableFor}
          extensionId={extension.id || ""}
          onRealoadExtensionsList={onRealoadExtensionsList}
          onToggleExtensionsOption={onToggleExtensionsOption}
          toggleDetail={toggleDetail}
        />
      )}
      {extension.instaledOn.length !== 0 && (
        <ExtensionDetailConfig
          installedOn={extension.instaledOn}
          extensionId={extension.id || ""}
          onRealoadExtensionsList={onRealoadExtensionsList}
          toggleDetail={toggleDetail}
        />
      )}
      {extension.extensionDependencies.length !== 0 && (
        <ExtensionDetailDependent
          extensionDependencies={extension.extensionDependencies}
        />
      )}
    </Drawer>
  );
};

export default ExtensionsStoreDetail;
