import { useNotificationContext } from "@4uhub/lib4uhub";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IDataProps } from "./models";

const TRANSLATION_PATH = "page.register.individualValidation.";

export const Data = memo(({ label, value, canCopy = true }: IDataProps) => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const copyHandler = useCallback(() => {
    navigator.clipboard.writeText(value);
    setMessage({
      message: t(`${TRANSLATION_PATH}copied_successfully`),
      type: "success",
    });
  }, [setMessage, t, value]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={(t) => ({
          color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
        })}
      >
        {label}:
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography>{value && value.length ? value : "-"}</Typography>
        {canCopy && value && value.length && (
          <Tooltip title={t(`${TRANSLATION_PATH}copy`)}>
            <IconButton size="small" onClick={copyHandler}>
              <ContentCopyRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
});
