import { GlobalAxios } from "../axios/axios-config";
import {
  ISalesContractPayerFinantial,
  ISalesContractPayerFinantialPayload,
} from "../models/sales-contract-payer-finantial";
import { IServiceUpdateParam } from "../utils/interfaces";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractPayerFinantial";

export const createSalesContractPayerFinantial = (
  payload: ISalesContractPayerFinantialPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSalesContractPayerFinantial = ({
  id,
  item,
}: IServiceUpdateParam<ISalesContractPayerFinantialPayload>) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const getSalesContractPayerFinantialById = (id: string) => {
  return GlobalAxios.get<ISalesContractPayerFinantial>(`${serviceURL}/${id}`);
};
