import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

import { IServiceUpdateParam } from "../utils/interfaces";
import {
  ISalesContractPayer,
  ISalesContractPayerPayload,
} from "../models/sales-contract-payer";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesContractPayer";

export const createSalesContractPayer = (
  payload: ISalesContractPayerPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSalesContractPayer = ({
  id,
  item,
}: IServiceUpdateParam<ISalesContractPayerPayload>) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findSalesContractPayerById = (id: string) => {
  return GlobalAxios.get<ISalesContractPayer>(`${serviceURL}/${id}`);
};

export const listSalesContractPayers = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};
export const removeSalesContractPayer = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};

export const approveSalesContractPayer = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Approve/${id}`);
};
export const inactivateSalesContractPayer = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Inactivate/${id}`);
};
export const reactivateSalesContractPayer = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Reactivate/${id}`);
};
export const suspendSalesContractPayer = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Suspend/${id}`);
};
