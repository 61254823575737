import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Table } from "@4uhub/lib4uhub";

import { Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { ISalesContract } from "../../../models/sales-contract";
import MainApi from "../../../services/mainApi.service";
import { buildTPath } from "./contracts.utils";
import SituationChip from "./SituationChip";

const x = process.env.REACT_APP_MAIN + "/api/v1/salesContract/";

const service = new MainApi(x);

const AdvantagesAndBenefitsClubPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formatDate = (date: string) => {
    if (!date) return "-";

    const [year, month, day] = date.split("-");

    return `${day}/${month}/${year}`;
  };

  const columns: GridColDef<ISalesContract>[] = [
    {
      field: "contractTitle",
      headerName: t(buildTPath("name"))!,
    },
    {
      field: "contractDate",
      headerName: t(buildTPath("contratation_date"))!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{formatDate(value)}</Typography>
      ),
    },
    {
      field: "terminationDate",
      headerName: t(buildTPath("termination_date"))!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{formatDate(value)}</Typography>
      ),
    },
    {
      field: "salesContractClassification",
      headerName: t(buildTPath("contract_classification"))!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{value?.name ?? "-"}</Typography>
      ),
    },
    {
      field: "situation",
      headerName: t(buildTPath("situation_status"))!,
      renderCell: ({ value }) => <SituationChip code={value?.code} />,
    },
  ];

  const handleRowClick = ({ id }: GridRowParams<any>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<any>
      showDefaultMacroFilters={false}
      service={service}
      columns={columns}
      title={t(buildTPath("title"))}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={t(buildTPath("search"))}
      addButtonLabel={t(buildTPath("add"))!}
      confirmOptions={{
        title: t(buildTPath("confirm.title")),
        description: t(buildTPath("confirm.description")),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default AdvantagesAndBenefitsClubPage;
