import { useFetch } from "@4uhub/lib4uhub";
import { Box, Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { signFile } from "../../../services/fileManager.service";
import { IIndividualPhotosProps, IPhoto } from "./models";
import { PhotoItem } from "./PhotoItem/PhotoItem";

const TRANSLATION_PATH = "page.register.individualValidation.";

export const IndividualPhotos = memo(({ photos }: IIndividualPhotosProps) => {
  const { t } = useTranslation();

  const [filesPath, setFilesPath] = useState<IPhoto[]>([]);

  const orderTo = ["1", "4", "2", "3"];

  const { sendRequest, loading } = useFetch(signFile);

  const signPhotos = useCallback(
    async (filePath: string, code: string) => {
      const { data, success } = await sendRequest(filePath);
      if (data && success) {
        setFilesPath((prev) => [...prev, { code, filePath: data }]);
      }
    },
    [sendRequest]
  );

  useEffect(() => {
    photos.map((photo) => signPhotos(photo.filePath, photo.mediaType.code!));
  }, [photos, signPhotos]);

  if (loading) return null;

  return (
    <Box
      sx={{
        p: 2,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Typography fontWeight="bold">
        {t(`${TRANSLATION_PATH}photos_sent`)}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ mt: 1, height: "100%", overflow: "auto" }}
      >
        {filesPath
          .sort((a, b) => orderTo.indexOf(a.code) - orderTo.indexOf(b.code))
          .map((photo) => (
            <PhotoItem photo={photo} key={photo.filePath} />
          ))}
      </Grid>
    </Box>
  );
});
