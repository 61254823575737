import { useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { memo } from "react";
import { IIndividualValidationContainerProps } from "./models";

export const IndividualValidationContainer = memo(
  ({ key, children }: IIndividualValidationContainerProps) => {
    const theme = useTheme();

    return (
      <motion.div
        key={key}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 10,
        }}
      >
        {children}
      </motion.div>
    );
  }
);
