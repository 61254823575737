import { DateInput, FormInput } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath =
  "page.4uSalesContract.registers.salesContractHistories.";

export const SalesContractHistoryForm = memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12} sm={12}>
        <FormInput
          size="small"
          name="title"
          fullWidth
          label={t(translationPath + "titleForm") || "Title"}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <DateInput
          name={"historyDate"}
          label={t(translationPath + "history_date") || "History Date"}
          initialDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormInput
          name="history"
          label={t(translationPath + "history") || "history"}
          size="small"
          fullWidth
          multiline
          minRows={4}
        />
      </Grid>
    </>
  );
});
