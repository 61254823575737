import { memo } from "react";
import { Link, Tooltip } from "@mui/material";

import { useNotificationContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

const translationPath = "page.evaluation.logs.";

interface IEvaluationIdProps {
  data: string;
}
const Evaluationid: React.FC<IEvaluationIdProps> = ({ data }) => {
  const { setMessage } = useNotificationContext();
  const { t } = useTranslation();

  const onClickHandler = () => {
    navigator.clipboard.writeText(data);
    setMessage({
      message: t(translationPath + "copied_successfully"),
      type: "success",
    });
  };

  return (
    <Tooltip title={data}>
      <Link
        component="button"
        variant="body2"
        onClick={onClickHandler}
        textOverflow={"ellipsis"}
        overflow={`hidden`}
      >
        {data}
      </Link>
    </Tooltip>
  );
};

export default memo(Evaluationid);
