import "./App.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import {
  AuthBetwheenApps,
  AuthContextProvider,
  Environment,
  ThemeContextProvider,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { CssBaseline } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { memo, useCallback, useEffect } from "react";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import routes from "./router/routes";
import EstablishmentParamService from "./services/establishmentParam.service";
import store from "./store/store";
import themeOptions from "./theme";

import {
  GlobalAxios,
  InterceptorRefresh,
  removeUserData,
} from "./axios/axios-config";
import { authService } from "./services/auth.service";
import { tokenService } from "./services/token.service";

function App() {
  const { setMessage } = useNotificationContext();

  const logoutUser = useCallback(() => {
    removeUserData();
    router.navigate("/login");
    setMessage({
      message: "Login expirado",
      type: "error",
    });
  }, [setMessage]);

  return (
    <Provider store={store}>
      <AuthContextProvider
        onLogoutUser={logoutUser}
        refreshToken={InterceptorRefresh}
        axios={GlobalAxios}
        identityApiPath={process.env.REACT_APP_IDENTIY!}
        mainApiPath={process.env.REACT_APP_MAIN!}
      >
        <ThemeContextProvider themeOptions={themeOptions}>
          <AppContext />
        </ThemeContextProvider>
      </AuthContextProvider>
    </Provider>
  );
}

const router = createBrowserRouter(routes);

const service = new EstablishmentParamService();

function AppContext() {
  const { sendRequest } = useFetch(service.getParam);

  const getGAKey = useCallback(async () => {
    const { data } = await sendRequest(7);
    if (data && data.length > 0) {
      ReactGA.initialize(data[0].value);
    }
  }, [sendRequest]);

  useEffect(() => {
    getGAKey();
  }, [getGAKey]);

  const environmentType = process.env.REACT_APP_ENVIRONMENT;

  return (
    <ConfirmProvider>
      <CssBaseline />
      {environmentType && environmentType !== "production" && (
        <Environment environment={environmentType} />
      )}
      <AuthBetwheenApps
        router={router}
        saveTokens={tokenService.saveTokens}
        tokenByToken={authService.tokenByToken}
        redirectRoute={(code, app) => `e/${code}/${app}`}
      >
        <RouterProvider router={router} />
      </AuthBetwheenApps>
    </ConfirmProvider>
  );
}

export default memo(App);
