import { ISelectType } from "@4uhub/lib4uhub";
import qs from "qs";
import { GlobalAxios } from "../axios/axios-config";

export class SerializedListService {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(this.route_path + "list", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  };
}

export default SerializedListService;
