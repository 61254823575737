import { z } from "zod";
const translationPath =
  "page.4uSalesContract.registers.salesContractHistories.errors.";
export const salesContractHistorySchema = z.object({
  id: z.string().optional(),
  title: z.string().min(1, translationPath + "title"),
  history: z.string().min(1, translationPath + "history"),
  historyDate: z.date({ invalid_type_error: translationPath + "historyDate" }),
});

export type TSalesContractHistoryForm = z.infer<
  typeof salesContractHistorySchema
>;
