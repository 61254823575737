import { FormInput } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import EntitySelect from "./EntitySelect/EntitySelect";
import { ISalesContractPayer } from "../../../../../models/sales-contract-payer";

const translationPath = "page.4uSalesContract.registers.salesContractPayer.";

export const SalesContractPayerForm = memo(
  ({
    type,
    dataSalesContractPayer,
  }: {
    type: string;
    dataSalesContractPayer: ISalesContractPayer | undefined;
  }) => {
    const { t } = useTranslation();
    return (
      <>
        {type !== "create" && dataSalesContractPayer?.situation && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "situation")}
              data={dataSalesContractPayer.situation.name?.toString() ?? ""}
            />
          </Grid>
        )}
        {type !== "create" && dataSalesContractPayer?.code && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "code")}
              data={dataSalesContractPayer.code.toString()}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="payerTitle"
            fullWidth
            label={t(translationPath + "title_payer") || "Payer Title"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="integrationId"
            fullWidth
            label={t(translationPath + "integration_code") || "Integration"}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EntitySelect />
        </Grid>
      </>
    );
  }
);
