import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { IndividualValidationContainer } from "../IndividualValidationContainer/IndividualValidationContainer";

const TRANSLATION_PATH = "page.register.individualValidation.";

export const NotFound = memo(() => {
  const { t } = useTranslation();

  return (
    <IndividualValidationContainer>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <PersonOffRoundedIcon
          sx={(t) => ({ fontSize: 60, color: t.palette.error.main })}
        />
        <Typography variant="h6" fontWeight="bold" textAlign="center">
          {t(`${TRANSLATION_PATH}not_found`)}
        </Typography>
      </Box>
    </IndividualValidationContainer>
  );
});
