import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  TTicketAutomationInternalTemplateTemplateForm,
  ticketAutomationInternalTemplateTemplateSchema,
} from "./ticketAutomationInternalTemplateTemplateSchema";
import {
  createTemplateInternalTemplates,
  getTemplateInternalTemplatesById,
  updateTemplateInternalTemplatesById,
} from "../../../../../../services/ticketAutomationInternalTemplateTemplate.service";
import { useCallback } from "react";
import { ITemplatesProps } from "./models";
import { Fields } from "./Fields/Fields";

const translationPath = "chatBot.page.templates.internalCrm.";

const Templates = ({ id, onBackButton }: ITemplatesProps) => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createTemplateInternalTemplates
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateTemplateInternalTemplatesById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getTemplateInternalTemplatesById
  );

  const updateHandler = (
    v: TTicketAutomationInternalTemplateTemplateForm,
    id: string
  ) =>
    update({
      item: {
        id: id,
        name: v.name,
        enable: v.enable,
        fields: v.fields.map((field) => ({
          screenOrder: parseInt(field.screenOrder),
          label: field.label,
          id: field.id === "" ? undefined : field.id,
          isRequired: field.isRequired,
          ticketAutomationInternalTemplateVariableId:
            field.ticketAutomationInternalTemplateVariable
              ? field.ticketAutomationInternalTemplateVariable.id
              : "",
        })),
      },
      id: id,
    });

  const saveHandler = (v: TTicketAutomationInternalTemplateTemplateForm) =>
    save({
      name: v.name,
      enable: v.enable,
      fields: v.fields.map((field) => ({
        screenOrder: parseInt(field.screenOrder),
        label: field.label,
        isRequired: field.isRequired,
        ticketAutomationInternalTemplateVariableId:
          field.ticketAutomationInternalTemplateVariable
            ? field.ticketAutomationInternalTemplateVariable.id
            : "",
      })),
    });

  const getHandler = useCallback(
    async (
      id: string
    ): Promise<Treturn<TTicketAutomationInternalTemplateTemplateForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TTicketAutomationInternalTemplateTemplateForm = {
          ...data,
          name: data.name,
          enable: data.enable,
          fields: data.ticketAutomationInternalTemplateFields.map((field) => ({
            ...field,
            ticketAutomationInternalTemplateVariable: {
              ...field.ticketAutomationInternalTemplateVariable,
              code: String(field.ticketAutomationInternalTemplateVariable.code),
            },
            screenOrder: field.screenOrder ? field.screenOrder.toString() : "",
            id: field.id,
          })),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTicketAutomationInternalTemplateTemplateForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "templates")}
      schema={ticketAutomationInternalTemplateTemplateSchema}
      subtitle={t(translationPath + "new_template")}
      subtitleWatchField={"name"}
      backButtonOnClick={onBackButton}
      redirectOnSave={false}
      onSuccess={onBackButton}
      isModalForm={true}
      propId={id}
      formActionsOnBottom={false}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign="end">
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              name="name"
              size="small"
              fullWidth
              label={t(translationPath + "name")}
            />
          </Grid>

          <Fields />
        </Grid>
      )}
    />
  );
};

export default Templates;
