import { Box, Divider, Skeleton } from "@mui/material";
import { memo } from "react";
import { IndividualDataLoading } from "./IndividualDataLoading/IndividualDataLoading";
import { IndividualPhotoLoading } from "./IndividualPhotoLoading/IndividualPhotoLoading";

export const IndividualValidationLoading = memo(() => {
  return (
    <Box
      sx={(t) => ({
        border: `1px solid ${t.palette.divider}`,
        borderRadius: 10,
      })}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton variant="rounded" width="30%" height={20} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={30} />
        </Box>
      </Box>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: 5 }}>
        <IndividualDataLoading />
        <IndividualDataLoading />
        <IndividualDataLoading />
        <IndividualDataLoading />
        <IndividualDataLoading />
      </Box>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: 5 }}>
        <IndividualDataLoading />
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Skeleton variant="rounded" width={200} height={20} />
        <Box
          sx={{ width: "100%", display: "flex", flexDirection: "row", gap: 2 }}
        >
          <IndividualPhotoLoading />
          <IndividualPhotoLoading />
        </Box>
        <Box
          sx={{ width: "100%", display: "flex", flexDirection: "row", gap: 2 }}
        >
          <IndividualPhotoLoading />
          <IndividualPhotoLoading />
        </Box>
      </Box>
    </Box>
  );
});
