import { memo } from "react";
import { useSalesContract } from "../../../../../../store/contexts/SalesContractContext";
import { SalesContractAttachment } from "../../../SalesContractAttachments/SalesContractAttachmentForm/SalesContractAttachment";
import { SalesContractHistory } from "../../../SalesContractHistories/SalesContractHistoryForm/SalesContractHistory";
import { SalesContractSubStipulant } from "../../../SalesContractSubStipulant/SalesContractSubStipulantForm/SalesContractSubStipulant";
import { SalesContractPayer } from "../../../SalesContractPayer/SalesContractPayerForm/SalesContractPayer";
import { SalesContractProduct } from "../../../SalesContractProduct/SalesContractProductForm/SalesContractProduct";

export const RenderForms = memo(() => {
  const { propId, tabName } = useSalesContract();
  switch (tabName) {
    case "history":
      return <SalesContractHistory id={propId} />;
    case "attachment":
      return <SalesContractAttachment id={propId} />;
    case "substipulant":
      return <SalesContractSubStipulant id={propId} />;
    case "payer":
      return <SalesContractPayer id={propId} />;
    case "product":
      return <SalesContractProduct />;
    default:
      return null;
  }
});
