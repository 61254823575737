import { Grid, Typography } from "@mui/material";
import { useFieldArray, useWatch } from "react-hook-form";

import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  AutoCompleteMulti,
  DateInput,
  FormArray,
  FormInput,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import RadioGroupInput from "../../../../../../components/RadioGroup/RadioGroupInput";
import MainApi from "../../../../../../services/mainApi.service";
import { TNotificationForm } from "../NotificationSchema";
import NotificationFiltersUsers from "./NotificationFiltersUsers";

const translationPath = "page.register.notifications.";

const PROFILE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Profile/";
const profileService = new MainApi<ISelectType>(PROFILE_ROUTE);

const GENDER_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genderService = new MainApi<ISelectType>(GENDER_ROUTE);

const NotificationFilters: React.FC = () => {
  const { control, formState } = useFormContext<TNotificationForm>();

  const { t } = useTranslation();

  const notificationType = useWatch({ name: "type", control });

  const documentsFieldArray = useFieldArray<TNotificationForm>({
    control,
    name: "notificationBatchFilters",
  });

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "filters")}
        name="notificationBatchFilters"
        addButtonLabel={
          t(translationPath + "add_new_filter") || "Add new filter"
        }
        formArray={documentsFieldArray}
        appendValue={{
          id: "",
          content: "",
          gender: null,
          isHolder: "both",
          isPayer: "both",
          language: null,
          maximumAge: "",
          minimumAge: "",
          name: "",
          profiles: [],
          title: "",
          users: [],
        }}
        fieldsObject={(index) => {
          const minimumAge = `notificationBatchFilters.${index}.minimumAge`;
          const maximumAge = `notificationBatchFilters.${index}.maximumAge`;
          const isPayer = `notificationBatchFilters.${index}.isPayer`;
          const isHolder = `notificationBatchFilters.${index}.isHolder`;
          const profiles = `notificationBatchFilters.${index}.profiles`;
          const users = `notificationBatchFilters.${index}.users`;
          const gender = `notificationBatchFilters.${index}.gender`;
          const ruleCodes = `notificationBatchFilters.${index}.ruleCodes`;
          const dateStartRule = `notificationBatchFilters.${index}.dateStartRules`;

          const errors = formState.errors.notificationBatchFilters;

          if (notificationType && notificationType.code === "3") {
            return (
              <>
                <Grid item xs={12} sm={12}>
                  <FormInput
                    size="small"
                    name={ruleCodes}
                    label={t(translationPath + "rule_codes") || "Rule Codes"}
                    fullWidth
                    helperText={t(translationPath + "rule_codes_helper")}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <DateInput
                    name={dateStartRule}
                    label={
                      t(translationPath + "date_start_rule") ||
                      "Rule Start Date"
                    }
                  />
                </Grid>
              </>
            );
          }

          return (
            <>
              {errors && (
                <Grid item sm={12}>
                  <Typography
                    variant="body2"
                    color={(theme) => theme.palette.error.main}
                  >
                    {t(errors[index]?.root?.message || "")}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <RadioGroupInput
                  name={isPayer}
                  groupLabel={t(translationPath + "payer") || "Payer"}
                  radios={[
                    { label: t(translationPath + "both"), value: "both" },
                    {
                      label: t(translationPath + "is_payer"),
                      value: "isPayer",
                    },
                    {
                      label: t(translationPath + "is_not_payer"),
                      value: "isNotPayer",
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RadioGroupInput
                  name={isHolder}
                  groupLabel={t(translationPath + "holder") || "Holder"}
                  radios={[
                    { label: t(translationPath + "both"), value: "both" },
                    {
                      label: t(translationPath + "is_holder"),
                      value: "isHolder",
                    },
                    {
                      label: t(translationPath + "is_not_holder"),
                      value: "isNotHolder",
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <AutoComplete
                  params={{ Identifier: "Gender" }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  size="small"
                  label={t(translationPath + "gender")}
                  name={gender}
                  request={genderService.list}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormInput
                  size="small"
                  name={minimumAge}
                  label={t(translationPath + "minimum_age") || "Minimum age"}
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormInput
                  size="small"
                  name={maximumAge}
                  label={t(translationPath + "maximum_age") || "Maximum age"}
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <AutoCompleteMulti
                  size="small"
                  label={t(translationPath + "profiles")}
                  name={profiles}
                  request={profileService.list}
                />
              </Grid>

              <NotificationFiltersUsers name={users} />
            </>
          );
        }}
      />
      {formState.errors.notificationBatchFilters && (
        <Grid item sm={12}>
          <Typography
            variant="body2"
            color={(theme) => theme.palette.error.main}
          >
            {t(
              formState.errors.notificationBatchFilters?.message ||
                formState.errors.notificationBatchFilters.root?.message ||
                ""
            )}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NotificationFilters;
