import { GlobalAxios } from "../axios/axios-config";
import { ISalesContractProductPayload } from "../models/sales-contract-product";

const SALES_CONTRACT_PRODUCT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractProduct/";

export const createSalesContractProduct = (
  item: ISalesContractProductPayload
) => {
  return GlobalAxios.post(SALES_CONTRACT_PRODUCT_ROUTE, item);
};
