import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TNotificationForm } from "../../NotificationSchema";
import { IContentFieldProps } from "./models";

const translationPath = "page.register.notifications.";

const variables = [
  "{{NomePagador}}",
  "{{ValorTotal}}",
  "{{NumeroNotificacao}}",
  "{{LoteNotificacao}}",
  "{{LoteDataGeracao}}",
];

export const ContentField = memo(
  ({ index, currentNotificationType }: IContentFieldProps) => {
    const { t } = useTranslation();

    const { control, setError, clearErrors } =
      useFormContext<TNotificationForm>();

    const content = useWatch({
      name: `notificationBatchContents.${index}.content`,
      control,
    });

    const validateVariablesAdded = useCallback(() => {
      if (!content || content.length === 0) return;
      const regex = /\{\{(.*?)\}\}/g;
      const variablesAdded = content.match(regex);

      if (!variablesAdded) return;
      const isAllVariablesValid = variablesAdded.every((variable) =>
        variables.includes(variable)
      );
      if (!isAllVariablesValid) {
        setError("notificationBatchContents", {
          message: `${t(`${translationPath}errors.variables`)}`,
        });
      } else {
        clearErrors("notificationBatchContents");
      }
    }, [content, setError, clearErrors, t]);

    useEffect(() => {
      validateVariablesAdded();
    }, [validateVariablesAdded]);

    return (
      <Grid item xs={12} sm={12}>
        <FormInput
          size="small"
          name={`notificationBatchContents.${index}.content`}
          label={t(translationPath + "content") || "Content"}
          fullWidth
          multiline
          minRows={4}
        />
        {!!(
          currentNotificationType && currentNotificationType.code === "3"
        ) && (
          <Typography
            variant="subtitle2"
            sx={(t) => ({
              color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
            })}
          >
            {t(translationPath + "content_info", {
              variables: variables.toString().replaceAll(",", ", "),
            })}
          </Typography>
        )}
      </Grid>
    );
  }
);
