import { Avatar, Box, Card, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import ExtensionIcon from "@mui/icons-material/Extension";

import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IExtensionsCard } from "../../models/extensions-store";
import ExtensionsCardModal from "./ExtensionsCardModal";
import ExtensionsPrice from "./ExtensionsPrice";

import { Modal } from "@4uhub/lib4uhub";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ExtensionsCardPrices from "./ExtensionsCardPrices";
import { InstallationButton } from "./InstallationButton/InstallationButton";

const translationPath = "components.extensionsCard.";

const ExtensionsCard = ({
  isAdded,
  card,
  openDetail,
  onRealoadExtensionsList,
}: IExtensionsCard) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [wasRequested, setWasRequested] = useState(false);

  const file = card.extensionMediaFiles.find(
    (file) => file.mediaType.code === "1"
  );

  const prices = card.extensionPricingRules;

  const onCloseHandler = () => setOpen(false);

  const isInstalled = card.instaledOn.length >= 1;

  const [showTooltip, setShowTooltip] = useState(false);

  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      setShowTooltip(
        titleRef.current.scrollHeight > titleRef.current.clientHeight
      );
    }
  }, [card.title]);

  const openInstallationHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const requestHandler = useCallback(() => {
    setWasRequested(true);
  }, []);

  return (
    <>
      <Modal open={open} onClose={onCloseHandler}>
        <ExtensionsCardModal
          extension={card}
          onClose={onCloseHandler}
          onRealoadExtensionsList={onRealoadExtensionsList}
          onRequestHandler={requestHandler}
        />
      </Modal>

      <Box
        sx={{
          width: "100%",
          minWidth: 240,
          cursor: "pointer",
        }}
      >
        <Card
          variant="outlined"
          sx={(theme) => ({
            p: 2,
            height: 250,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            backgroundColor:
              theme.palette.mode === "light" ? "#fff" : theme.palette.grey[900],
            transition: "all 0.2s ease-out",
            "&:hover": {
              borderColor: theme.palette.primary.main,
            },
          })}
          onClick={openDetail}
        >
          <Avatar alt="Extension" sx={{ width: 56, height: 56, mb: 2 }}>
            {file && <img src={file.cdnDomain + "/" + file.filePath} alt="" />}
            {!file && <ExtensionIcon />}
          </Avatar>
          <Stack
            height={40}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Tooltip
              title={card.title}
              disableHoverListener={!showTooltip}
              disableFocusListener={!showTooltip}
            >
              <Typography
                ref={titleRef}
                variant="subtitle1"
                textAlign={"center"}
                lineHeight={1.2}
                style={{
                  width: 200,
                  height: 40,
                  textAlign: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {card.title}
              </Typography>
            </Tooltip>
          </Stack>
          <Typography
            variant="subtitle2"
            style={{
              width: 200,
              height: 45,
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {card.extensionTags
              .slice(0, 5)
              .map((tag) => tag.name)
              .join(", ")}
          </Typography>
          {isInstalled && (
            <Stack
              px={2}
              minWidth={220}
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              position={"absolute"}
              top={18}
              left={-75}
              sx={{ backgroundColor: "#8dff83", rotate: "320deg" }}
            >
              <Typography textAlign={"center"}>
                {t(translationPath + "installed")}
              </Typography>
            </Stack>
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            position={"absolute"}
            bottom={15}
          >
            <InstallationButton
              isRequiredSetup={card.isRequiredSetup}
              isInstalled={isInstalled}
              wasRequested={wasRequested}
              onOpenDetail={openDetail}
              onOpenInstall={openInstallationHandler}
            />
            <Stack
              px={2}
              minWidth={220}
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={0.5}
            >
              {prices.length === 0 && (
                <ExtensionsPrice
                  color="success"
                  chipLabel={`${t(translationPath + "free")}`}
                  variant="outlined"
                />
              )}
              {!isInstalled &&
                prices
                  .filter((item) => item.typePricing.code === "1")
                  .splice(-1)
                  .map((price) => (
                    <ExtensionsCardPrices
                      icon={<DownloadForOfflineIcon color="action" />}
                      title={`${t(translationPath + "tooltip_install")}`}
                      price={price.value}
                      key={price.id}
                    />
                  ))}
              {prices
                .filter((item) => item.typePricing.code === "2")
                .splice(-1)
                .map((price) => (
                  <ExtensionsCardPrices
                    icon={<CalendarMonthIcon color="action" />}
                    title={`${t(translationPath + "tooltip_month")}`}
                    price={price.value}
                    key={price.id}
                  />
                ))}
            </Stack>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ExtensionsCard;
