import { ISelectType } from "@4uhub/lib4uhub";
import {
  INewSalesContractPriceList,
  ISalesContractPriceList,
} from "../models/sales-contract-price-list";
import { GlobalAxios } from "../axios/axios-config";

const SALES_CONTRACT_PRICE_LIST_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractPriceList/";

export const createSalesContractPriceList = (
  item: INewSalesContractPriceList
) => {
  return GlobalAxios.post(SALES_CONTRACT_PRICE_LIST_ROUTE, item);
};

export const getSalesContractPriceListById = (id: string) => {
  return GlobalAxios.get<ISalesContractPriceList>(
    SALES_CONTRACT_PRICE_LIST_ROUTE + id
  );
};

export const listSalesContractPriceList = () => {
  return GlobalAxios.get<ISelectType[]>(
    `${SALES_CONTRACT_PRICE_LIST_ROUTE}list`
  );
};

export const updateSalesContractPriceListById = ({
  item,
  id,
}: {
  item: INewSalesContractPriceList;
  id: string;
}) => {
  return GlobalAxios.put(SALES_CONTRACT_PRICE_LIST_ROUTE + id, item);
};

export const approveSalesContractPriceList = (id: string) => {
  return GlobalAxios.put(
    `${SALES_CONTRACT_PRICE_LIST_ROUTE}ApprovalDate/${id}`
  );
};
