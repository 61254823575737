import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  TTicketAutomationInternalTemplateVariableForm,
  ticketAutomationInternalTemplateVariableSchema,
} from "./ticketAutomationInternalTemplateVariableSchema";
import {
  createVariablesInternalTemplates,
  getVariablesInternalTemplatesById,
  updateVariablesInternalTemplatesById,
} from "../../../../../../services/ticketAutomationInternalTemplateVariable.service";
import { useCallback } from "react";
import { IVariablesProps } from "./models";

import MainApi from "../../../../../../services/mainApi.service";
import AutoCompleteCategories from "./AutoCompleteCategories";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const translationPath = "chatBot.page.templates.internalCrm.";

const Variables = ({ id, onBackButton }: IVariablesProps) => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createVariablesInternalTemplates
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateVariablesInternalTemplatesById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getVariablesInternalTemplatesById
  );

  const updateHandler = (
    v: TTicketAutomationInternalTemplateVariableForm,
    id: string
  ) =>
    update({
      item: {
        id: id,
        name: v.name,
        enable: v.enable,
        categoryId: v.category.id,
        dataTypeId: v.dataType.id,
      },
      id: id,
    });

  const saveHandler = (v: TTicketAutomationInternalTemplateVariableForm) =>
    save({
      name: v.name,
      enable: v.enable,
      categoryId: v.category.id,
      dataTypeId: v.dataType.id,
    });

  const getHandler = useCallback(
    async (
      id: string
    ): Promise<Treturn<TTicketAutomationInternalTemplateVariableForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TTicketAutomationInternalTemplateVariableForm = {
          ...data,
          name: data.name,
          code: data.code,
          enable: data.enable,
          category: data.category,
          dataType: data.dataType,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTicketAutomationInternalTemplateVariableForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "variables")}
      schema={ticketAutomationInternalTemplateVariableSchema}
      subtitle={t(translationPath + "new_variable")}
      subtitleWatchField={"name"}
      backButtonOnClick={onBackButton}
      redirectOnSave={false}
      onSuccess={onBackButton}
      isModalForm={true}
      propId={id}
      formActionsOnBottom={false}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign="end">
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              name="name"
              size="small"
              fullWidth
              label={t(translationPath + "name")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{
                identifier: "TicketAutomationInternalTemplateDataType",
              }}
              getOptionLabel={(option) => option.name}
              size="small"
              label={t(translationPath + "data_type")}
              name="dataType"
              request={genericService.list}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteCategories />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Variables;
