import { ISelectType } from "@4uhub/lib4uhub";
import qs from "qs";
import { GlobalAxios } from "../axios/axios-config";

const TASY_SCHEDULE_RECORDS_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyScheduleRecord/list";

export const tasyScheduleRecordList = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(TASY_SCHEDULE_RECORDS_ROUTE, {
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};
