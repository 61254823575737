import { z } from "zod";
const translationPath =
  "page.4uSalesContract.registers.salesContractSubStipulant.errors.";

const legalEntitySchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});

const individualSchema = z.object({
  identifier: z.string(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});

const situationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "situation" }
);

export const salesContractSubStipulantSchema = z
  .object({
    id: z.string().optional(),
    integrationId: z.string().nullish(),
    code: z.string().nullish(),
    entity: z.enum(["QPF", "QPJ"]).optional(),
    situation: situationSchema.nullish(),
    individual: individualSchema.optional().nullish(),
    legalEntity: legalEntitySchema.optional().nullish(),
  })
  .superRefine((value, context) => {
    const { entity, individual, legalEntity } = value;

    if (entity === "QPF" && !individual) {
      context.addIssue({
        code: "custom",
        path: ["individual"],
        message: translationPath + "individual_person",
      });
    } else if (entity === "QPJ" && !legalEntity) {
      context.addIssue({
        code: "custom",
        path: ["legalEntity"],
        message: translationPath + "legal_entity",
      });
    }

    value.entity = undefined;
  });

export type TSalesContractSubStipulantForm = z.infer<
  typeof salesContractSubStipulantSchema
>;
