import { memo, useCallback, useState } from "react";
import { ISalesContractProps } from "../../SalesContractHistories/SalesContractHistoryForm/models";
import { useParams } from "react-router-dom";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { useTranslation } from "react-i18next";
import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import {
  salesContractSubStipulantSchema,
  TSalesContractSubStipulantForm,
} from "./salesContractSubStipulantSchema";
import {
  createSalesContractSubStipulant,
  findSalesContractSubStipulantById,
  updateSalesContractSubStipulant,
} from "../../../../../services/salesContractSubStipulant.service";
import { Grid } from "@mui/material";
import { ISalesContractSubStipulant } from "../../../../../models/sales-contract-sub-stipulant";
import { SalesContractSubStipulantForm } from "./SalesContractSubStipulantForm";
import { SalesContractSubStipulantInformation } from "./SalesContractSubStipulantInformation";

const translationPath =
  "page.4uSalesContract.registers.salesContractSubStipulant.";

export const SalesContractSubStipulant = memo(({ id }: ISalesContractProps) => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();
  const [dataSalesContractSubStipulant, setDataSalesContractSubStipulant] =
    useState<ISalesContractSubStipulant>();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractSubStipulant
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateSalesContractSubStipulant
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    findSalesContractSubStipulantById
  );

  const handleEntity = (data: any, type: "QPF" | "QPJ") => {
    if (!data) return null;

    switch (type) {
      case "QPJ":
        return {
          ...data,
          corporateName: data?.corporateName,
        };
      default:
        return {
          ...data,
          individualName: data?.individualName,
        };
    }
  };

  const updateHandler = (v: TSalesContractSubStipulantForm, id: string) => {
    return update({
      item: {
        id: id,
        integrationId: v.integrationId || "",
        individualId: v.individual ? v.individual.identifier : undefined,
        legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
        salesContractId: salesContractId ?? "",
      },
      id: id,
    });
  };

  const saveHandler = (v: TSalesContractSubStipulantForm) =>
    save({
      integrationId: v.integrationId || "",
      individualId: v.individual ? v.individual.identifier : undefined,
      legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
      salesContractId: salesContractId ?? "",
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesContractSubStipulantForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setDataSalesContractSubStipulant(data);
        const newData: TSalesContractSubStipulantForm = {
          ...data,
          code: data.code ? data.code.toString() : "",
          entity: data.legalEntity ? "QPJ" : "QPF",
          individual: handleEntity(data.individual, "QPF"),
          legalEntity: handleEntity(data.legalEntity, "QPJ"),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;
  return (
    <FormContainer<TSalesContractSubStipulantForm>
      title={t(translationPath + "title")}
      subtitle=""
      getFunction={getHandler}
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      onSuccess={successHandle}
      showBackButton={false}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      loading={loading}
      schema={salesContractSubStipulantSchema}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          {type !== "create" && dataSalesContractSubStipulant?.approvalDate ? (
            <SalesContractSubStipulantInformation
              data={dataSalesContractSubStipulant}
            />
          ) : (
            <SalesContractSubStipulantForm
              type={type}
              dataSalesContractSubStipulant={dataSalesContractSubStipulant}
            />
          )}
        </Grid>
      )}
    />
  );
});
