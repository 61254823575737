import { BackButton } from "@4uhub/lib4uhub";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Actions } from "./Data/Actions/Actions";
import { DataList } from "./Data/DataList/DataList";
import { IIndividualDataProps } from "./models";

const TRANSLATION_PATH = "page.register.individualValidation.";

export const IndividualData = memo(
  ({ individualValidation, onNext }: IIndividualDataProps) => {
    const { t } = useTranslation();

    const { id } = individualValidation;

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <Box
        sx={{
          p: 2,
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 2,
          flexWrap: matches ? "wrap" : undefined,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <BackButton />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t(`${TRANSLATION_PATH}individual_validation`)}
            </Typography>
          </Box>
          <DataList {...individualValidation} />
        </Box>
        <Actions
          id={id}
          statusCode={individualValidation.status.code ?? ""}
          onNext={onNext}
        />
      </Box>
    );
  }
);
