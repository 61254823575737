import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import {
  TSalesContractHistoryForm,
  salesContractHistorySchema,
} from "./salesContractHistorySchema";
import { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { ISalesContractProps } from "./models";
import {
  createSalesContractHistory,
  getSalesContractHistoryById,
  updateSalesContractHistoryById,
} from "../../../../../services/salesContractHistory.service";
import { ISalesContractHistory } from "../../../../../models/sales-contract-history";
import { SalesContractHistoryInformation } from "./SalesContractHistoryInformation";
import { SalesContractHistoryForm } from "./SalesContractHistoryForm";

const translationPath =
  "page.4uSalesContract.registers.salesContractHistories.";

export const SalesContractHistory = memo(({ id }: ISalesContractProps) => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();

  const [dataHistory, setDataHistory] = useState<ISalesContractHistory>();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractHistory
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateSalesContractHistoryById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getSalesContractHistoryById
  );

  const updateHandler = (v: TSalesContractHistoryForm, id: string) => {
    return update({
      item: {
        id: id,
        title: v.title,
        history: v.history,
        salesContractId: salesContractId ?? "",
        historyDate: v.historyDate.toISOString().substring(0, 10),
      },
      id: id,
    });
  };

  const saveHandler = (v: TSalesContractHistoryForm) =>
    save({
      title: v.title,
      history: v.history,
      salesContractId: salesContractId ?? "",
      historyDate: v.historyDate.toISOString().substring(0, 10),
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesContractHistoryForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setDataHistory(data);
        const newData: TSalesContractHistoryForm = {
          ...data,
          title: data.title,
          history: data.history,
          historyDate: new Date(`${data.historyDate}T00:00:00`),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TSalesContractHistoryForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "title")}
      schema={salesContractHistorySchema}
      subtitle={t(translationPath + "new_history")}
      subtitleWatchField={"title"}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      actionsBar={dataHistory?.approvalDate ? false : true}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          {type !== "create" && dataHistory?.approvalDate ? (
            <SalesContractHistoryInformation data={dataHistory} />
          ) : (
            <SalesContractHistoryForm />
          )}
        </Grid>
      )}
    />
  );
});
