import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import LegalEntityAdrresses from "./LegalEntityAddresses";
import LegalEntityContacts from "./LegalEntityContacts";
import LegalEntityBasicData from "./LegalEntityBasicData";

import { LegalEntitySchema, TLegalEntityForm } from "./LegalEntitySchema";
import LegalEntityService from "../../../../services/legalEntity.service";
import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import { useCallback } from "react";

const legalEntity = new LegalEntityService();

const Generic = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(legalEntity.create);

  const { sendRequest: update, loading: uLoading } = useFetch(
    legalEntity.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    legalEntity.getItemById
  );

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TLegalEntityForm>> => {
      const { data, success } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            addressess:
              data.addressess &&
              data.addressess.map((address) => ({
                id: address.id,
                addressType: address.addressType,
                otherType: address.otherType,
                otherNeighborhood: address.neighborhood ? false : true,
                zipCode:
                  address.zipCode.substring(0, 5) +
                  "-" +
                  address.zipCode.substring(5),
                city: address.city,
                provincy: address.city?.provincy,
                addressDescription: address.addressDescription,
                neighborhoodName: address.neighborhoodName
                  ? address.neighborhoodName
                  : null,
                neighborhood: address.neighborhood
                  ? address.neighborhood
                  : null,
                number: address.number || undefined,
                complement: address.complement || undefined,
              })),
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const handleSave = (v: TLegalEntityForm) => {
    return save({
      ...v,
      fiscalContributorId: v.fiscalContributor.id,
      taxRegimeId: v.taxRegime.id,
      establishmentId: v.establishment.id,
      addressess: v.addressess.map((address) => ({
        cityId: address.city?.id || "",
        neighborhoodId: !address.otherNeighborhood
          ? address.neighborhood?.id
          : null,
        addressTypeId: address.addressType.id,
        otherType: address.otherType || null,
        zipCode: address.zipCode.replaceAll("-", ""),
        addressDescription: address.addressDescription,
        neighborhoodName: address.otherNeighborhood
          ? address.neighborhoodName
          : null,
        number: address.number || null,
        complement: address.complement,
      })),
      contacts: v.contacts.map((c) => ({
        ...c,
        contactTypeId: c.contactType.id,
      })),
    });
  };

  const handleUpdate = (v: TLegalEntityForm) => {
    return update({
      item: {
        ...v,
        fiscalContributorId: v.fiscalContributor.id,
        taxRegimeId: v.taxRegime.id,
        establishmentId: v.establishment.id,
        addressess: v.addressess.map((address) => ({
          id: address.id,
          cityId: address.city?.id || "",
          neighborhoodId: !address.otherNeighborhood
            ? address.neighborhood?.id
            : undefined,
          addressTypeId: address.addressType.id,
          otherType: address.otherType || null,
          zipCode: address.zipCode.replaceAll("-", ""),
          addressDescription: address.addressDescription,
          neighborhoodName: address.otherNeighborhood
            ? address.neighborhoodName
            : undefined,
          number: address.number || null,
          complement: address.complement,
        })),
        contacts: v.contacts.map((c) => ({
          ...c,
          contactTypeId: c.contactType.id,
        })),
      },
      id: v.id!,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TLegalEntityForm>
      loading={loading}
      title={t("page.register.legalPerson.legalPersons")}
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      schema={LegalEntitySchema}
      subtitle={t("page.register.legalPerson.new_legalPerson")}
      subtitleWatchField={"corporateName"}
      fields={(type) => (
        <Grid container spacing={2}>
          {/* Basic Data */}
          <LegalEntityBasicData type={type} />

          {/* Contacts  */}
          <LegalEntityContacts />
          {/* Addresses */}
          <LegalEntityAdrresses />
        </Grid>
      )}
    />
  );
};

export default Generic;
