import { z } from "zod";
const translationPath =
  "page.4uSalesContract.registers.salesContractPayerFinantial.errors.";

const bilingMethodSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "biling_method" }
);

export const salesContractPayerFinantialSchema = z.object({
  id: z.string().optional(),
  initialDate: z.date({ invalid_type_error: translationPath + "initial_date" }),
  endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
  monthlyPaymentDueDate: z.string().min(1, translationPath + "code"),
  mail: z.string().nullish(),
  phone: z.string().nullish(),
  whatsappNumber: z.string().nullish(),
  observation: z.string().nullish(),
  bilingMethod: bilingMethodSchema,
});

export type TSalesContractPayerFinantialForm = z.infer<
  typeof salesContractPayerFinantialSchema
>;
