import { IRadio, RadioGroupInput, useFormContext } from "@4uhub/lib4uhub";
import { ReactElement, useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TSalesContractSubStipulantForm } from "../salesContractSubStipulantSchema";

const translationPath =
  "page.4uSalesContract.registers.salesContractSubStipulant.";

export type TEntity = "QPF" | "QPJ";

export type TForm = TSalesContractSubStipulantForm & { entity: TEntity };

const Checkboxes = (): ReactElement => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<TForm>();
  const entity = useWatch({ control, name: "entity", defaultValue: "QPF" });

  const radios: IRadio<TEntity>[] = [
    {
      label: t(translationPath + "individual_person"),
      value: "QPF",
    },
    { label: t(translationPath + "legal_entity"), value: "QPJ" },
  ];

  const setToIndividualPerson = useCallback(() => {
    setValue("entity", "QPF");

    setValue("legalEntity", null);
  }, [setValue]);

  const setToLegalEntity = useCallback(() => {
    setValue("entity", "QPJ");

    setValue("individual", null);
  }, [setValue]);

  useEffect(() => {
    if (entity !== "QPJ") return;

    setToLegalEntity();
  }, [entity, setToLegalEntity]);

  useEffect(() => {
    if (entity !== "QPF") return;

    setToIndividualPerson();
  }, [entity, setToIndividualPerson]);

  return (
    <RadioGroupInput
      inline
      disableBorder
      name="entity"
      groupLabel=""
      radios={radios}
    />
  );
};

export default Checkboxes;
