import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

import { IServiceUpdateParam } from "../utils/interfaces";
import { ISaleSeller, ISaleSellerPayload } from "../models/sales-seller";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesSeller";

export const createSaleSeller = (payload: ISaleSellerPayload) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSaleSeller = ({
  id,
  item,
}: IServiceUpdateParam<ISaleSellerPayload>) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findSaleSellerById = (id: string) => {
  return GlobalAxios.get<ISaleSeller>(`${serviceURL}/${id}`);
};

export const listSaleSellers = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};
export const removeSaleSeller = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};

export const approveSalesSeller = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Approve/${id}`);
};
export const inactivateSalesSeller = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Inactivate/${id}`);
};
export const reactivateSalesSeller = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Reactivate/${id}`);
};
export const suspendSalesSeller = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Suspend/${id}`);
};
