import { OverflowTooltip, Table } from "@4uhub/lib4uhub";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { format } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { INotifiedUser } from "../../../../../models/notifications";
import MainApiService from "../../../../../services/mainApi.service";

const CONTENT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Notification/NotificationBatchUsers";
const contentService = new MainApiService<INotifiedUser>(CONTENT_ROUTE);

const translationPath = "page.register.notifiedUsers.";

const NotifiedUsersPage = () => {
  const { id } = useParams();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formatDate = (value: any) => {
    if (!value) return "-";

    return format(new Date(value), "Pp", {
      locale: language === "pt-BR" ? ptBR : enUS,
    }).replaceAll(",", " -");
  };

  const columns: GridColDef<INotifiedUser>[] = [
    {
      field: "user",
      headerName: t(`${translationPath}user`) || "User",
      renderCell: ({ row: { user } }) =>
        user ? (
          <OverflowTooltip
            text={user.fullName}
            tooltip={user.fullName}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "readDate",
      headerName: t(`${translationPath}read_date`) || "Read Date",
      maxWidth: 150,
      renderCell: ({ value }) => formatDate(value),
    },
    {
      field: "acceptanceDate",
      maxWidth: 150,
      headerName: t(`${translationPath}acceptance_date`) || "Acceptance Date",
      renderCell: ({ value }) => formatDate(value),
    },
    {
      field: "acceptanceData",
      headerName: t(`${translationPath}acceptance_data`) || "Acceptance Data",
      renderCell: ({ row: { acceptanceData } }) =>
        acceptanceData && acceptanceData.length > 0 ? (
          <OverflowTooltip
            text={acceptanceData}
            tooltip={acceptanceData}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
  ];

  const props = useMemo(() => {
    return {
      notificationBatchId: id,
    };
  }, [id]);

  return (
    <Table<INotifiedUser>
      showDefaultMacroFilters={false}
      service={contentService}
      columns={columns}
      title={t(translationPath + "notified_users")}
      searchInput
      backButton
      serviceProps={props}
      showDeleteButton={false}
      defaultActions={false}
      showButton={false}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a user"
      }
    />
  );
};

export default NotifiedUsersPage;
