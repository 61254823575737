import { memo } from "react";
import { useSalesContract } from "../../../../../../store/contexts/SalesContractContext";
import { SalesSeller } from "../../../SalesSellers/SalesSellerForm/SalesSeller";

export const RenderForms = memo(() => {
  const { propId, tabName } = useSalesContract();
  switch (tabName) {
    case "seller":
      return <SalesSeller id={propId} />;
    default:
      return null;
  }
});
