import { Button, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { updateApproveStatus } from "../../../../../services/individualValidation.service";
import { DisapproveModal } from "./DisapproveModal/DisapproveModal";
import { IActionsProps } from "./models";

const TRANSLATION_PATH = "page.register.individualValidation.actions.";

export const Actions = memo(({ id, statusCode, onNext }: IActionsProps) => {
  const { setMessage } = useNotificationContext();

  const { t } = useTranslation();

  const { id: currentId } = useParams();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const [isApproving, setIsApproving] = useState(false);

  const [isDisapproving, setIsDisapproving] = useState(false);

  const { sendRequest, loading } = useFetch(updateApproveStatus);

  const approveHandler = useCallback(async () => {
    setIsApproving(true);
    const { data, success } = await sendRequest({ id, approve: true });
    if (data && success) {
      setMessage({
        message: t(`${TRANSLATION_PATH}approved`),
        type: "success",
      });
      setIsApproving(false);
      if (currentId) {
        return navigate(-1);
      }
      onNext();
    }
  }, [sendRequest, setMessage, onNext, navigate, t, currentId, id]);

  const disapprovingHandler = useCallback((value: boolean) => {
    setIsDisapproving(value);
  }, []);

  return (
    <Box
      sx={{
        height: "fit-content",
        width: matches ? "100%" : "fit-content",
        display: "flex",
        justifyContent: "end",
        gap: 2,
      }}
    >
      {statusCode === "1" && (
        <DisapproveModal
          id={id}
          isApproving={isApproving}
          loading={loading}
          onNext={onNext}
          onDisapproving={disapprovingHandler}
        />
      )}
      {statusCode !== "2" && (
        <Button
          color="success"
          variant="contained"
          startIcon={<CheckRoundedIcon />}
          disabled={loading || isDisapproving}
          loading={isApproving && loading}
          onClick={approveHandler}
        >
          {t(`${TRANSLATION_PATH}approve`)}
        </Button>
      )}
    </Box>
  );
});
