import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

import { IServiceUpdateParam } from "../utils/interfaces";
import {
  ISalesContractSubStipulant,
  ISalesContractSubStipulantPayload,
} from "../models/sales-contract-sub-stipulant";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractSubStipulant";

export const createSalesContractSubStipulant = (
  payload: ISalesContractSubStipulantPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSalesContractSubStipulant = ({
  id,
  item,
}: IServiceUpdateParam<ISalesContractSubStipulantPayload>) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findSalesContractSubStipulantById = (id: string) => {
  return GlobalAxios.get<ISalesContractSubStipulant>(`${serviceURL}/${id}`);
};

export const listSalesContractSubStipulants = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};
export const removeSalesContractSubStipulant = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};

export const approveSalesContractSubStipulant = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Approve/${id}`);
};
export const inactivateSalesContractSubStipulant = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Inactivate/${id}`);
};
export const reactivateSalesContractSubStipulant = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Reactivate/${id}`);
};
export const suspendSalesContractSubStipulant = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Suspend/${id}`);
};
