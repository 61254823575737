import { GlobalAxios } from "../axios/axios-config";
import {
  IChangeApproveStatusParams,
  IIndividualValidation,
} from "../models/individual-validations";

const BASE_URL = process.env.REACT_APP_MAIN + "/api/v1/IndividualValidation/";

export const getIndividualValidationById = (id: string) => {
  return GlobalAxios.get<IIndividualValidation>(BASE_URL + id);
};

export const updateApproveStatus = (item: IChangeApproveStatusParams) => {
  return GlobalAxios.put(BASE_URL + item.id, item);
};
