import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormArray,
  FormInput,
  SwitchInput,
  useFormContext,
} from "@4uhub/lib4uhub";
import { TTicketAutomationInternalTemplateTemplateForm } from "../ticketAutomationInternalTemplateTemplateSchema";
import { memo } from "react";
import { listVariablesInternalTemplates } from "../../../../../../../services/ticketAutomationInternalTemplateVariable.service";

const translationPath = "chatBot.page.templates.internalCrm.";

export const Fields = memo(() => {
  const { control, formState } =
    useFormContext<TTicketAutomationInternalTemplateTemplateForm>();
  const { t } = useTranslation();

  const fieldsFieldArray =
    useFieldArray<TTicketAutomationInternalTemplateTemplateForm>({
      control,
      name: "fields",
    });

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "fields")}
        name="fields"
        addButtonLabel={t(translationPath + "add_new_item") || "Add new item"}
        formArray={fieldsFieldArray}
        appendValue={{
          id: "",
          label: "",
          ticketAutomationInternalTemplateVariable: null,
          isRequired: true,
          screenOrder: "",
        }}
        fieldsObject={(index) => {
          const label = `fields.${index}.label`;
          const isRequired = `fields.${index}.isRequired`;
          const screenOrder = `fields.${index}.screenOrder`;
          const ticketAutomationInternalTemplateVariable = `fields.${index}.ticketAutomationInternalTemplateVariable`;

          return (
            <>
              <Grid item xs={12} sm={2}>
                <FormInput
                  size="small"
                  name={label}
                  label={t(translationPath + "label") || "Label"}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <AutoComplete
                  size="small"
                  name={ticketAutomationInternalTemplateVariable}
                  label={t(translationPath + "variables")}
                  getOptionLabel={({ name }) => name}
                  request={listVariablesInternalTemplates}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormInput
                  size="small"
                  type="number"
                  name={screenOrder}
                  label={t(translationPath + "screen_order") || "Screen Order"}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <SwitchInput
                  name={isRequired}
                  label={t(translationPath + "is_required")}
                />
              </Grid>
            </>
          );
        }}
      />
      {formState.errors.fields && (
        <Typography variant="body2" color={"error"}>
          {formState.errors.fields.message
            ? t(`${formState.errors.fields.message}`)
            : formState.errors.fields.root?.message
            ? t(`${formState.errors.fields.root?.message}`)
            : null}
        </Typography>
      )}
    </Grid>
  );
});
