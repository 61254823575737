import { z } from "zod";

const translationPath = "chatBot.page.templates.internalCrm.errors.";

const genericSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "data_type" }
);

const categoriesSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "categories" }
);

export const ticketAutomationInternalTemplateVariableSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  code: z.number().optional(),
  name: z.string().min(1, translationPath + "name"),
  category: categoriesSchema,
  dataType: genericSchema,
});

export type TTicketAutomationInternalTemplateVariableForm = z.infer<
  typeof ticketAutomationInternalTemplateVariableSchema
>;
