import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { Grid } from "@mui/material";
import { memo } from "react";
import { ISalesContractPayer } from "../../../../../models/sales-contract-payer";

const translationPath = "page.4uSalesContract.registers.salesContractPayer.";

export const SalesContractPayerInformation = memo(
  ({ data }: { data: ISalesContractPayer }) => {
    const { t } = useTranslation();
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "situation")}
            data={data.situation.name?.toString() ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "code")}
            data={data.code ? data.code.toString() : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "title_payer")}
            data={data.payerTitle ? data.payerTitle : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "address_type")}
            data={data.addressType ? data.addressType.name : ""}
          />
        </Grid>
        {data.individual ? (
          <Grid item xs={12} sm={12}>
            <FormInformation
              label={t(translationPath + "individual_person")}
              data={data.individual?.individualName || ""}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12}>
            <FormInformation
              label={t(translationPath + "legal_entity")}
              data={data.legalEntity?.corporateName || ""}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <FormInformation
            label={t(translationPath + "integration_code")}
            data={data?.integrationId ?? "-"}
          />
        </Grid>
      </>
    );
  }
);
