import { Box, Chip, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { IStatusProps } from "./models";

const TRANSLATION_PATH = "page.register.individualValidation.";

export const Status = memo(({ statusCode }: IStatusProps) => {
  const { t } = useTranslation();

  const status =
    statusCode === "2"
      ? t(`${TRANSLATION_PATH}approved`)
      : statusCode === "3"
      ? t(`${TRANSLATION_PATH}disapproved`)
      : t(`${TRANSLATION_PATH}pending`);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={(t) => ({
          color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
        })}
      >
        {t(`${TRANSLATION_PATH}status`)}:
      </Typography>
      <Chip
        size="small"
        label={status}
        color={
          statusCode === "2"
            ? "success"
            : statusCode === "3"
            ? "error"
            : "default"
        }
      />
    </Box>
  );
});
