import ImageNotSupportedRoundedIcon from "@mui/icons-material/ImageNotSupportedRounded";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const TRANSLATION_PATH = "page.register.individualValidation.photo_item.";

export const ErrorFallback = memo(() => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(t) => ({
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        color: t.palette.grey[t.palette.mode === "light" ? 700 : 400],
      })}
    >
      <ImageNotSupportedRoundedIcon fontSize="large" />
      <Typography>{t(`${TRANSLATION_PATH}error`)}</Typography>
    </Box>
  );
});
