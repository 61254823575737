import {
  Emoji,
  EmojiPicker,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Box, TextField } from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TBodyInput } from "./models";
import { TextFormatActions } from "./TextFormatActions/TextFormatActions";
import { AddVariablesButton } from "./AddVariablesButton/AddVariablesButton";
import { VariablesExamples } from "./VariablesExamples/VariablesExamples";
import { CURLY_BRACKETS_VALIDATOR } from "../../../../utils/Validators/regexValidators";
import { REMOVE_CURLY_BRACKETS } from "../../../../utils/conversions";
import MainApi from "../../../../services/mainApi.service";
import { useFieldArray } from "react-hook-form";
import { useTemplateWhats } from "../../../../store/contexts/TemplateWhatsContext";

const VARIABLES_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationMacro/";

const variablesService = new MainApi<ISelectType>(VARIABLES_ROUTE);
const TICKET_AUTO_INTERNAL_TEMPLATE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationInternalTemplateField/";

const variablesInternalTemplate = new MainApi<ISelectType>(
  TICKET_AUTO_INTERNAL_TEMPLATE
);

const translationPath = "components.bodyInput.";

export const BodyInput = memo(
  ({
    automationId,
    internalTemplateId,
    componentType,
    field,
    error,
    ...otherProps
  }: TBodyInput) => {
    const { t } = useTranslation();

    const { setError, clearErrors, control } = useFormContext();

    const { templateWhatsBasicData } = useTemplateWhats();

    const [options, setOptions] = useState<ISelectType[]>([]);

    const [message, setMessage] = useState<string>(
      field.value || templateWhatsBasicData.body || ""
    );

    const [variables, setVariables] = useState<string[]>([]);

    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const { sendRequest } = useFetch(variablesService.list);
    const { sendRequest: sendRequestInternalTemplate } = useFetch(
      variablesInternalTemplate.list
    );

    const { remove } = useFieldArray({ name: "bodyExamples", control });

    const fetchVariablesHandler = useCallback(async () => {
      if (!internalTemplateId || internalTemplateId.length === 0) {
        if (!automationId || automationId.length === 0) return;
        const { data, success } = await sendRequest({
          TicketAutomationId: automationId,
          ComponentType: componentType,
        });
        if (data && success) {
          setOptions(data);
        }
      } else {
        const { data, success } = await sendRequestInternalTemplate({
          TicketAutomationInternalTemplateId: internalTemplateId,
        });
        if (data && success) {
          setOptions(data);
        }
      }
    }, [
      sendRequest,
      sendRequestInternalTemplate,
      automationId,
      internalTemplateId,
      componentType,
    ]);

    const handleEmojiClick = useCallback(
      (emoji: Emoji) => {
        const ref = inputRef.current;
        if (ref) {
          const start = ref.selectionStart ?? 0;
          const end = ref.selectionEnd ?? 0;
          const text =
            message.slice(0, start) + emoji.native + message.slice(end);
          setMessage(text);
          setCursorPosition(start + emoji.native.length);
        }
      },
      [message]
    );

    const handleEmojiOpen = useCallback(() => {
      inputRef.current?.focus();
    }, [inputRef]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const ref = inputRef.current;
      if (ref) {
        const start = ref.selectionStart ?? 0;
        setMessage(e.target.value);
        setCursorPosition(start);
      } else {
        setMessage(e.target.value);
      }
    };

    const addVariableHandler = useCallback(
      (variable: string) => {
        const ref = inputRef.current;
        if (ref) {
          const start = ref.selectionStart ?? 0;
          const end = ref.selectionEnd ?? 0;
          const text =
            message.slice(0, start) + `{{${variable}}}` + message.slice(end);
          setMessage(text);
          setCursorPosition(start + variable.length);
        }
      },
      [message]
    );

    useEffect(() => {
      if (inputRef.current && cursorPosition !== null) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        setCursorPosition(null);
      }
    }, [cursorPosition]);

    useEffect(() => {
      field.onChange(message);
    }, [field, message]);

    useEffect(() => {
      const matches = message.match(CURLY_BRACKETS_VALIDATOR);
      if (matches) {
        const variables = matches.map((match) => match);
        setVariables((oldVariables) => {
          oldVariables.map((v, i) => {
            if (!variables.includes(v)) {
              remove(i);
            }
            return v;
          });
          return variables;
        });
      } else {
        setVariables([]);
      }
    }, [message, remove]);

    useEffect(() => {
      if (variables.length === 0) {
        remove(0);
      }
    }, [variables, remove]);

    useEffect(() => {
      const optionsNames = options.map((option) =>
        option.name.replace(REMOVE_CURLY_BRACKETS, "")
      );

      if (
        variables.length > 0 &&
        variables.some(
          (v) => !optionsNames.includes(v.replace(REMOVE_CURLY_BRACKETS, ""))
        )
      ) {
        setError(field.name, {
          type: "pattern",
          message: `${t(translationPath + "error")}`,
        });
      } else {
        clearErrors(field.name);
      }
    }, [field.name, options, variables, setError, clearErrors, t]);

    useEffect(() => {
      fetchVariablesHandler();
    }, [fetchVariablesHandler]);

    useEffect(() => {
      setMessage((prevState) => {
        if (
          templateWhatsBasicData.body === "" ||
          !templateWhatsBasicData.body
        ) {
          return templateWhatsBasicData.body;
        }
        return prevState;
      });
    }, [templateWhatsBasicData.body, setMessage]);

    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <TextField
          {...field}
          onChange={handleChange}
          value={message}
          error={!!error}
          {...otherProps}
          helperText={error ? t(error?.message || "") : ""}
          inputRef={inputRef}
        />
        <Box sx={{ display: "flex" }}>
          <EmojiPicker
            size="small"
            onEmojiClick={handleEmojiClick}
            onEmojiOpen={handleEmojiOpen}
          />
          <TextFormatActions
            inputRef={inputRef}
            message={message}
            setMessage={setMessage}
          />
          <AddVariablesButton
            onAddVariable={addVariableHandler}
            inputRef={inputRef}
            options={options}
          />
        </Box>
        {variables && variables.length > 0 && !error && (
          <VariablesExamples variables={variables} />
        )}
      </Box>
    );
  }
);
