import { z } from "zod";

const translationsPath = "page.4uSalesContract.registers.salesSeller.errors.";

const situationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationsPath + "situation" }
);
const individualSchema = z.object(
  {
    id: z.string(),
    individualName: z.string(),
    document: z.string(),
  },
  {
    invalid_type_error: `${translationsPath + "individual"}`,
  }
);
export const salesSellerSchema = z.object({
  id: z.string().optional(),
  titleSeller: z.string().min(1, translationsPath + "title"),
  situation: situationSchema.nullish(),
  individual: individualSchema,
});

export type TSalesSellerForm = z.infer<typeof salesSellerSchema>;
