import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { INewTimeRule, ITimeRule } from "../models/time-rules";

const TIME_RULE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationTimes/";

const TICKET_AUTOMATION_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomation/";

export const createTimeRule = (item: INewTimeRule) => {
  return GlobalAxios.post(TIME_RULE_ROUTE, item);
};

export const getTimeRuleById = (id: string) => {
  return GlobalAxios.get<ITimeRule>(TIME_RULE_ROUTE + id);
};

export const updateTimeRuleById = ({
  item,
  id,
}: {
  item: INewTimeRule;
  id: string;
}) => {
  return GlobalAxios.put(TIME_RULE_ROUTE + id, item);
};

export const getTypeofTimeAutomationRuleByCode = (
  automationCode: string | undefined
) => {
  return GlobalAxios.get<ISelectType[]>(
    TICKET_AUTOMATION_ROUTE + automationCode + "/TypeofTimeFraction"
  );
};
