import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IVariablesInternalTemplates,
  INewVariablesInternalTemplates,
} from "../models/internal-templates";

const TICKET_AUTO_INTERNAL_TEMPLATE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationInternalTemplateVariable";

export const createVariablesInternalTemplates = (
  item: INewVariablesInternalTemplates
) => {
  return GlobalAxios.post(TICKET_AUTO_INTERNAL_TEMPLATE, item);
};

export const getVariablesInternalTemplatesById = (id: string) => {
  return GlobalAxios.get<IVariablesInternalTemplates>(
    TICKET_AUTO_INTERNAL_TEMPLATE + "/" + id
  );
};

export const updateVariablesInternalTemplatesById = ({
  item,
  id,
}: {
  item: INewVariablesInternalTemplates;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_AUTO_INTERNAL_TEMPLATE + "/" + id, item);
};

export const listVariablesInternalTemplates = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(
    TICKET_AUTO_INTERNAL_TEMPLATE + "/list",
    {
      params: params,
    }
  );
};
