import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Chip, ChipOwnProps } from "@mui/material";
const translationsPath = "page.4uSalesContract.registers.salesSeller.";

type TCode = "1" | "2" | "3" | "4";

const SituationChip = ({
  name,
  code,
}: {
  name: string;
  code: TCode;
}): ReactElement => {
  const { t } = useTranslation();

  const translate = (path: string): string => t(translationsPath + path);

  const color = useMemo((): ChipOwnProps["color"] => {
    switch (code) {
      case "1":
        return "success";
      case "2":
        return "warning";
      case "3":
        return "error";
      case "4":
        return "primary";
      default:
        return;
    }
  }, [code]);

  const label = () => {
    switch (code) {
      case "1":
        return translate("active");
      case "2":
        return translate("suspended");
      case "3":
        return translate("inactive");
      case "4":
        return translate("approved");
      default:
        return name;
    }
  };

  return <Chip size="small" color={color} label={label()} />;
};

export default SituationChip;
