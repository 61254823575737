import { z } from "zod";

const translationPath = "page.register.notifications.errors.";

const typeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "type" }
);

const siteAppSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const languageSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const notificationBatchContentSchema = z
  .object({
    language: languageSchema.nullable(),
    title: z.string().min(1, translationPath + "title"),
    content: z.string().min(1, translationPath + "content"),
  })
  .superRefine((val, ctx) => {
    if (!val.language) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["language"],
        message: translationPath + "language",
      });
    }
  });

const genderSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const profileSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const notificationBatchFilterSchema = z.object({
  gender: genderSchema.nullable(),
  minimumAge: z.string().nullish(),
  maximumAge: z.string().nullish(),
  isPayer: z.string(),
  isHolder: z.string(),
  profiles: z.array(profileSchema),
  users: z.array(userSchema),
  ruleCodes: z.string().nullish(),
  dateStartRules: z.date().nullish(),
});

export const notificationSchema = z
  .object({
    id: z.string().optional(),
    type: typeSchema,
    sendPush: z.boolean(),
    siteApps: z.array(siteAppSchema).min(1, translationPath + "site_app"),
    notificationBatchContents: z
      .array(notificationBatchContentSchema)
      .min(1, translationPath + "contents"),
    notificationBatchFilters: z
      .array(notificationBatchFilterSchema)
      .min(1, "Ao menos um filtro é obrigatório"),
  })
  .superRefine((val, ctx) => {
    val.notificationBatchFilters.map((filter, i) => {
      if (val.type.code !== "3") {
        if (filter.isHolder === "both" && filter.isPayer === "both") {
          if (
            !filter.gender &&
            !filter.maximumAge &&
            !filter.minimumAge &&
            filter.profiles.length === 0 &&
            filter.users.length === 0
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["notificationBatchFilters", i],
              message: translationPath + "notification_batch_filter",
            });
            return false;
          }
          return true;
        }
      } else {
        if (!filter.ruleCodes || filter.ruleCodes.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["notificationBatchFilters", i, "ruleCodes"],
            message: translationPath + "rule_codes",
          });
        }
        if (!filter.dateStartRules) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["notificationBatchFilters", i, "dateStartRules"],
            message: translationPath + "date_start_rule",
          });
        }
      }
      return true;
    });
  });

export type TNotificationForm = z.infer<typeof notificationSchema>;
