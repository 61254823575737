import { Modal } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { memo } from "react";
import { ErrorFallback } from "../ErrorFallback/ErrorFallback";
import { IPhotoModalProps } from "./models";

export const PhotoModal = memo(
  ({
    title,
    photoPath,
    open,
    error,
    onClose,
    onGetError,
  }: IPhotoModalProps) => {
    return (
      <Modal
        open={open}
        onClose={onClose}
        title={title ?? undefined}
        maxDialogWidth={"lg"}
      >
        <Box
          sx={{
            width: "auto",
            height: "80vh",
            display: "flex",
            flexDirection: "row",
            aligntItems: "center",
            justifyContent: "center",
          }}
        >
          {error && <ErrorFallback />}
          {!error && (
            <img
              src={photoPath}
              alt={title ?? "Photo"}
              style={{ height: "100%", borderRadius: 10 }}
              onError={onGetError}
            />
          )}
        </Box>
      </Modal>
    );
  }
);
