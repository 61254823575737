import {
  AutoComplete,
  FormInput,
  ISelectType,
  SwitchInput,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import MainApi from "../../../../../services/mainApi.service";
import { TWhatsappForm } from "./wathsappSchema";
import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { WhatsappTemplateAutomation } from "./WhatsappTemplateAutomation";
import { useParams } from "react-router-dom";
import { listTemplatesInternalTemplates } from "../../../../../services/ticketAutomationInternalTemplateTemplate.service";

const TICKET_SERVICE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServices/";
const ticketService = new MainApi<ISelectType>(TICKET_SERVICE_ROUTE);

const translationPath = "chatBot.page.templates.whatsapp.";

export const WhatsappTemplateData = memo(
  ({ status }: { status?: ISelectType }) => {
    const { t } = useTranslation();

    const { id } = useParams();

    const [ticketServicesOptions, setTicketServicesOptions] = useState<
      ISelectType[]
    >([]);

    const { setValue, getValues } = useFormContext<TWhatsappForm>();

    const templateTicketService = getValues("ticketService");

    const { sendRequest } = useFetch(ticketService.list);

    const fetchTicketServices = useCallback(async () => {
      const { data, success } = await sendRequest(null);
      if (data && success) {
        if (data.length === 1) {
          setValue("ticketService", data[0]);
        }
        setTicketServicesOptions(data);
      }
    }, [sendRequest, setValue]);

    useEffect(() => {
      fetchTicketServices();
    }, [fetchTicketServices]);

    return (
      <Grid item container spacing={2} sm={12}>
        <Grid
          item
          xs={12}
          sm={12}
          textAlign={"end"}
          sx={
            id && status
              ? {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }
              : undefined
          }
        >
          {id && status && (
            <FormInformation
              label={t(translationPath + "status")}
              data={status.name}
            />
          )}
          <SwitchInput label={t(translationPath + "enable")} name="enable" />
        </Grid>

        <Grid item xs={12} sm={ticketServicesOptions.length === 0 ? 6 : 12}>
          <FormInput
            label={t(translationPath + "name")}
            name="name"
            size="small"
            autoFocus
            fullWidth
          />
        </Grid>

        <WhatsappTemplateAutomation />

        {ticketServicesOptions.length === 0 && (
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle2" color="error">
              {t(translationPath + "no_ticket_service")}
            </Typography>
          </Grid>
        )}

        {ticketServicesOptions.length === 1 && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "ticket_service")}
              data={ticketServicesOptions[0].name}
              minHeight={40}
            />
          </Grid>
        )}

        {id && templateTicketService && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "ticket_service")}
              data={templateTicketService.name}
              minHeight={40}
            />
          </Grid>
        )}

        {!id && ticketServicesOptions.length > 1 && (
          <Grid item xs={12} sm={6}>
            <AutoComplete
              name="ticketService"
              label={t(translationPath + "ticket_service")}
              options={ticketServicesOptions}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <AutoComplete
            size="small"
            name="ticketAutomationInternalTemplate"
            label={t(translationPath + "internal_template")}
            getOptionLabel={({ name }) => name}
            request={listTemplatesInternalTemplates}
          />
        </Grid>
      </Grid>
    );
  }
);
