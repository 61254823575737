import { Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import {
  AppLoading,
  ConfigProvider,
  DomainProvider,
  IConfiguration,
  IPrivilege,
  PrivilegesContextProvider,
  useFetch,
  UserProvider,
} from "@4uhub/lib4uhub";
import ReactGA from "react-ga4";
import InstalledExtensionContainer from "../components/InstalledExtensionsContainer";
import Menu from "../components/Menu/Menu";
import { IUser } from "../models/user";
import ConfigurationsService from "../services/configurations.service";
import EstablishmentParamService from "../services/establishmentParam.service";
import EstablishmentsService from "../services/establishments.service";
import LanguagesService from "../services/languages.service";
import {
  savePermissions,
  userMe,
  userMePrivileges,
} from "../services/user.service";
import { AppProvider } from "../store/appContex";

const configService = new ConfigurationsService();
const service = new EstablishmentParamService();
const establishmentsService = new EstablishmentsService();
const languageService = new LanguagesService();

const RootLayout = () => {
  const { sendRequest: userPrivilegesRequest, loading: userPrivilegeLoading } =
    useFetch(userMePrivileges, {
      startLoadingTrue: true,
    });

  const { sendRequest: userMeRequest, loading: userMeLoading } = useFetch(
    userMe,
    {
      startLoadingTrue: true,
    }
  );

  const { sendRequest: get } = useFetch(configService.getAll);

  const [configs, setConfigs] = useState<IConfiguration>();

  const getConfigs = useCallback(async () => {
    const { data } = await get(null);
    if (data) {
      if (data.id) {
        setConfigs({
          ...data,
          webAppearance: {
            code: data.webAppearance.code,
            menuOpen: false,
          },
        });
      }
    }
  }, [get]);

  useEffect(() => {
    getConfigs();
  }, [getConfigs]);

  const [privileges, setPrivileges] = useState<IPrivilege[]>([]);

  const [user, setUser] = useState<IUser>();

  const getPrivileges = useCallback(async () => {
    const { data } = await userPrivilegesRequest(null);
    if (data) {
      savePermissions(data);
      setPrivileges(data);
    }
  }, [userPrivilegesRequest]);

  const getUserMe = useCallback(async () => {
    const { data } = await userMeRequest(null);
    if (data) {
      setUser(data);
    }
  }, [userMeRequest]);

  useEffect(() => {
    getPrivileges();
    getUserMe();
  }, [getPrivileges, getUserMe]);

  const { pathname } = useLocation();

  const { sendRequest } = useFetch(service.getParam);

  const getGAKey = useCallback(async () => {
    const { data } = await sendRequest(7);
    if (data && data.length > 0) {
      ReactGA.initialize(data[0].value);
    }
  }, [sendRequest]);

  useEffect(() => {
    getGAKey();
  }, [getGAKey]);

  // Google analytcs
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
    });
  }, [pathname]);

  const loading = useMemo(
    () => userMeLoading || userPrivilegeLoading,
    [userMeLoading, userPrivilegeLoading]
  );

  const { sendRequest: createConfig } = useFetch(configService.create);

  const onConfigChangeHandler = useCallback(
    async (cnfig: IConfiguration) => {
      createConfig({
        deleteMediaFileIds: [],
        establishmentStandardId: cnfig.establishment.id,
        languageId: cnfig.language.id,
        mediaFileIds: [],
        parentName: "ApplicationUser",
        softwareAppearanceCode: cnfig.webAppearance.code,
      });
    },
    [createConfig]
  );

  const i18nProvider = useTranslation();

  return (
    <ConfigProvider
      languageRequest={languageService.list}
      listEstablishments={establishmentsService.list}
      i18n={i18nProvider}
      configInitialState={configs}
      onConfigChange={onConfigChangeHandler}
    >
      {loading ? (
        <AppLoading />
      ) : (
        <PrivilegesContextProvider privileges={privileges}>
          {user ? (
            <UserProvider user={user}>
              <AppProvider>
                <DomainProvider>
                  <InstalledExtensionContainer>
                    <Stack
                      direction={{ sm: "column", lg: "row" }}
                      sx={(theme) => ({
                        height: "calc(100vh)",
                        [theme.breakpoints.down("lg")]: {
                          overflow: "hidden",
                        },
                        overflow: "hidden",
                      })}
                    >
                      <Menu />
                      <motion.main
                        style={{
                          width: "calc(100% - 40px)",
                          margin: "10px 20px 10px 10px",
                          marginLeft: "20px",
                          height: "calc(100% - 20px)",
                          overflow: "auto",
                        }}
                        id="main"
                        key={pathname}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 0.7,
                          ease: [0.6, -0.05, 0.01, 0.99],
                        }}
                      >
                        <Outlet></Outlet>
                      </motion.main>
                    </Stack>
                  </InstalledExtensionContainer>
                </DomainProvider>
              </AppProvider>
            </UserProvider>
          ) : (
            <></>
          )}
        </PrivilegesContextProvider>
      )}
    </ConfigProvider>
  );
};

export default RootLayout;
