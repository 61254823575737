import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAutomationRules } from "../../../../../../../store/contexts/AutomationRulesContext";
import { TTimeRuleForm } from "./timeRuleSchema";
import { getTypeofTimeAutomationRuleByCode } from "../../../../../../../services/timeRule.service";

const translationPath = "components.timeRules.";

export const TypeofTimeFraction = memo(() => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<TTimeRuleForm>();

  const { automation } = useAutomationRules();

  const [, setCurrentAutomation] = useState<ISelectType | null>(automation);

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(getTypeofTimeAutomationRuleByCode);

  const fetchTypeofTimeFractions = useCallback(async () => {
    if (!automation) return;
    const { data, success } = await sendRequest(automation.code);
    if (data && success) {
      let options = data;
      setOptions(options.map((d) => ({ id: d.id, name: d.name })));
    }
  }, [sendRequest, automation]);

  useEffect(() => {
    fetchTypeofTimeFractions();
  }, [fetchTypeofTimeFractions]);

  useEffect(() => {
    setCurrentAutomation((oldState) => {
      if (oldState !== automation) {
        setValue("type", null);
      }
      return automation;
    });
  }, [automation, setValue]);

  return (
    <Grid item xs={12} sm={5}>
      <AutoComplete
        name="type"
        label={t(translationPath + "type") || "Type"}
        getOptionLabel={(option) => option.name}
        size="small"
        fullWidth
        options={options}
      />
    </Grid>
  );
});
