import { GlobalAxios } from "../axios/axios-config";
import {
  ISalesContractAttachment,
  ISalesContractAttachmentPayload,
} from "../models/sales-contract-attachments";

const SALES_CONTRACT_HISTORY_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractAttachment/";

export const createSalesContractAttachment = (
  item: ISalesContractAttachmentPayload
) => {
  return GlobalAxios.post(SALES_CONTRACT_HISTORY_ROUTE, item);
};

export const getSalesContractAttachmentById = (id: string) => {
  return GlobalAxios.get<ISalesContractAttachment>(
    SALES_CONTRACT_HISTORY_ROUTE + id
  );
};

export const updateSalesContractAttachmentById = ({
  item,
  id,
}: {
  item: ISalesContractAttachmentPayload;
  id: string;
}) => {
  return GlobalAxios.put(SALES_CONTRACT_HISTORY_ROUTE + id, item);
};

export const approveSalesContractAttachment = (id: string) => {
  return GlobalAxios.put(`${SALES_CONTRACT_HISTORY_ROUTE}ApprovalDate/${id}`);
};
