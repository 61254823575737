import { memo } from "react";
import { useParams } from "react-router-dom";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { useTranslation } from "react-i18next";
import { AutoComplete, FormContainer, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { listContractProducts } from "../../../../../services/salesProductCtr.service";
import { listContracProductPriceList } from "../../../../../services/contractProductPriceList.service";
import { createSalesContractProduct } from "../../../../../services/salesContractProduct.service";
import {
  salesContractProductSchema,
  TSalesContractProductForm,
} from "./salesContractProductSchema";

const translationPath = "page.4uSalesContract.registers.salesContractProducts.";

export const SalesContractProduct = memo(() => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractProduct
  );

  const saveHandler = (v: TSalesContractProductForm) =>
    save({
      salesContractId: salesContractId ?? "",
      salesProductCtrId: v.salesProductCtr.id,
      salesProductCtrPriceListId: v.salesProductCtrPriceList.id,
    });

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading;

  return (
    <FormContainer<TSalesContractProductForm>
      saveFunction={saveHandler}
      loading={loading}
      title={t(translationPath + "title")}
      schema={salesContractProductSchema}
      subtitle={t(translationPath + "new")}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "product")}
              name="salesProductCtr"
              request={listContractProducts}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "price_list")}
              name="salesProductCtrPriceList"
              request={listContracProductPriceList}
            />
          </Grid>
        </Grid>
      )}
    />
  );
});
