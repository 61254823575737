import { FormTabs } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { SalesContractSubStipulantPage } from "../SalesContractSubStipulant/SalesContractSubStipulantPage";
import { SalesContractProductPage } from "../SalesContractProduct/SalesContractProductPage";

const translationPath =
  "page.4uSalesContract.registers.baseContracts.contractualObject.";

export const ContractualObjects = memo(() => {
  const { t } = useTranslation();
  let tabs = [
    {
      label: t(translationPath + "product"),
      component: <SalesContractProductPage />,
    },
    {
      label: t(translationPath + "substipulant"),
      component: <SalesContractSubStipulantPage />,
    },
  ];
  return (
    <Box
      sx={(t) => ({
        p: 1,
        borderRadius: 1,
        backgroundColor:
          t.palette.mode === "light" ? "#fff" : t.palette.grey[900],
      })}
    >
      <FormTabs
        tabs={tabs}
        sxTab={{
          backgroundColor: "transparent",
        }}
      />
    </Box>
  );
});
