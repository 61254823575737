import ZoomOutMapRoundedIcon from "@mui/icons-material/ZoomOutMapRounded";
import { Box, Grid } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorFallback } from "./ErrorFallback/ErrorFallback";
import { IPhotoItemProps } from "./models";
import PhotoLabel from "./PhotoLabel/PhotoLabel";
import { PhotoModal } from "./PhotoModal/PhotoModal";

const TRANSLATION_PATH = "page.register.individualValidation.photo_item.";

export const PhotoItem = memo(({ photo }: IPhotoItemProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [error, setError] = useState(false);

  const getError = useCallback(() => {
    console.log("oi");
    setError(true);
  }, []);

  const getLabel = () => {
    switch (photo.code) {
      case "1":
        return t(`${TRANSLATION_PATH}person`);
      case "2":
        return t(`${TRANSLATION_PATH}front_doc`);
      case "3":
        return t(`${TRANSLATION_PATH}back_doc`);
      case "4":
        return t(`${TRANSLATION_PATH}person_doc`);
    }
  };

  const openHandler = () => setOpen(true);

  const closeHandler = () => setOpen(false);

  return (
    <>
      <PhotoModal
        title={getLabel() ?? "Photo"}
        open={open}
        photoPath={photo.filePath}
        error={error}
        onClose={closeHandler}
        onGetError={getError}
      />
      <Grid item xs={12} sm={6}>
        <PhotoLabel label={getLabel() ?? "Photo"} />
        <Box
          sx={(t) => ({
            mt: 2,
            position: "relative",
            width: "100%",
            minWidth: 150,
            minHeight: 150,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: error ? `1px solid ${t.palette.divider}` : undefined,
            borderRadius: 1,
          })}
          onClick={error ? undefined : openHandler}
        >
          {error && <ErrorFallback />}
          {!error && (
            <Box
              component="img"
              onError={getError}
              src={photo.filePath}
              alt={getLabel()}
              sx={{
                width: "100%",
                minHeight: 150,
                minWidth: 150,
                display: "block",
              }}
            />
          )}
          {!error && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                opacity: 0,
                transition: "opacity 0.3s ease",
                "&:hover": {
                  cursor: "pointer",
                  opacity: 1,
                },
              }}
            >
              <ZoomOutMapRoundedIcon sx={{ fontSize: 32, color: "white" }} />
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
});
