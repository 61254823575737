import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  AutoCompleteMulti,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  TagsInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

// Schema
import MainApiService, { MainApi } from "../../../../services/mainApi.service";
import ExtensionFiles from "./ExtensionFiles";
import { TExtensionForm, extensionSchema } from "./ExtensionSchema";

import { IInputFile } from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { NonEmptyArray } from "../../../../models/requests";
import ExtensionsService from "../../../../services/extensions.service";

const EXTENSION_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Extension/";
const extensionService = new ExtensionsService(EXTENSION_ROUTE);

const SOFTWARE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Software/";
const softwareList = new MainApiService<ISelectType>(SOFTWARE_ROUTE);

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const translationPath = "page.register.extensions.";

const Extension = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [extensionFilesId, setExtensionFilesId] = useState<string[]>();
  let deleteFilesList: string[] = [];

  const { sendRequest: update, loading: uLoading } = useFetch(
    extensionService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    extensionService.getItemById
  );

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TExtensionForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TExtensionForm = {
          ...data,
          extensionMediaFiles: data.extensionMediaFiles.map((media) => ({
            mediaType: media.mediaType,
            file: {
              id: media.id,
              fileName: media.fileName,
              fileSize: media.fileSize,
              isPublic: media.isPublic,
              mimeType: media.mimeType,
              cdnDomain: media.cdnDomain,
              filePath: media.filePath,
            },
          })),
          displayOrder: data.displayOrder.toString(),
          extensionTags: data.extensionTags.map(
            (e) => e.name
          ) as NonEmptyArray<string>,
          extensionTargets: data.extensionTargets.map((target) => ({
            id: target.generic.id,
            name: target.generic.name,
          })),
        };

        const filesId = newData.extensionMediaFiles.map(
          (media) => media.file?.id || ""
        );
        if (filesId) setExtensionFilesId(filesId);
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const updateHandler = (v: TExtensionForm, id: string) => {
    const updateFilesIds = v.extensionMediaFiles.filter((media) =>
      media.file ? !extensionFilesId?.includes(media.file.id!) : ""
    );

    return update({
      item: {
        ...v,
        id: id,
        displayOrder: Number(v.displayOrder),
        extensionTags: v.extensionTags,
        deleteMediaFilesIds: deleteFilesList,
        mediaFilesIds: updateFilesIds.map((media) =>
          media.file ? media.file.id! : ""
        ),
        softwareId: v.software.id,
        extensionDependencies: v.extensionDependencies
          ? v.extensionDependencies.map((d) => d.id)
          : null,
        targetIds: v.extensionTargets.map((target) => target.id),
      },
      id: id,
    });
  };

  const loading = uLoading || gLoading;

  return (
    <FormContainer<TExtensionForm>
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "extension")}
      schema={extensionSchema}
      subtitle={t(translationPath + "new_extension")}
      subtitleWatchField={"name"}
      defaultValues={{
        isRequiredSetup: false,
      }}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
            <SwitchInput
              name="isRequiredSetup"
              label={t(translationPath + "is_required_setup")}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              name="displayOrder"
              type="number"
              fullWidth
              label={t(translationPath + "presentation_order")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              filterSelectedOptions
              size="small"
              label={t(translationPath + "software")}
              name={"software"}
              request={softwareList.list}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name")}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="title"
              fullWidth
              label={t(translationPath + "title")}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              size="small"
              multiline
              name="description"
              fullWidth
              minRows={4}
              label={t(translationPath + "description")}
            />
          </Grid>

          <Grid item xs={12}>
            <TagsInput
              name="extensionTags"
              size="small"
              label={t(translationPath + "tags")}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              params={{ id: id }}
              size="small"
              label={t(translationPath + "extension_dependencies")}
              name={"extensionDependencies"}
              request={extensionService.list}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              params={{ Identifier: "SiteAppType" }}
              size="small"
              label={t(translationPath + "extension_targets")}
              name={"extensionTargets"}
              request={genericService.list}
            />
          </Grid>

          <ExtensionFiles onDeleteFileHandler={onDeleFileHandler} />
        </Grid>
      )}
    />
  );
};

export default Extension;
