import { ISelectType } from "@4uhub/lib4uhub";
import { ECalendarMode } from "./Calendar/models";

export interface IFilter {
  speciality: null | string;
  professionals: string[];
  exams: string[];
  situation: string | null;
  period: string;
  source: ECalendarSource;
  mode: ECalendarMode;
}

export enum ECalendarSource {
  BOTH = "-1",
  EXAM = "2",
  CONSULT = "3",
}

export interface IData {
  id: string;
  name: string;
}

export interface ISpeciality {
  id: string;
  integrationId: string;
  name: string;
  heathCodeCboIntegrationId: string;
  enable: boolean;
  isMedicalGuide: boolean;
  displayOrder: number;
}

export interface IProfessional {
  establishmentCode: number;
  identifier: number;
  calendarName: string;
  calendarShortName: string;
  descriptionSite: string;
  tasyScheduleTypeCode: ETasyScheduleTypeCode;
  tasyScheduleTypeName: string;
  individualIdentifier: string;
  individualName: string;
  enableCode: string;
  enableName: string;
  medicalSpecialtyCode: string;
  medicalSpecialtyName: string;
  observation: string;
  encounterTypeCode: number;
  encounterTypeName: string;
  exclusiveSectorCode: number;
  exclusiveSectorName: string;
  groupingIdentifier: number;
  groupingName: string;
}

export interface IProfessionalSearchParams {
  MedicalSpecialtyCode: string;
  searchBy?: string;
}

export interface IGroupExam {
  groupingIdentifier: number;
  groupingName: string;
  displayOrder: number;
  typeCalendar: string;
  enableCode: string;
}

export enum EStatusAvailability {
  ALL_AVAIABLE = "AllAvailable",
  EXISTS_AVAIABLE = "ExistsAvailable",
  NON_AVAIABLE = "FullAppointment",
}

export interface IAvaiableDate {
  calendarDate: string;
  statusAvailability: EStatusAvailability;
}

export enum ETasyScheduleTypeCode {
  CONSULTATION = "3",
  EXAM = "2",
}

export enum EcalendarStatusCode {
  CANCELED = "C",
  CONFIMED = "AC",
  CONFIMED2 = "CN",
  EXECUTED = "E",
  NORMAL = "N",
  FREE = "L",
  BLOCKED = "B",
}

export interface ICalendar {
  tasyScheduleTypeCode: number;
  tasyScheduleTypeName: string;
  groupingIdentifier: number;
  groupingName: string;
  roomIdentifier: number;
  roomName: string;
  identifier: number;
  insuranceCode: number;
  insuranceName: string;
  insuranceCardNumber: string;
  insuranceCardValidity: string;
  calendarClassificationName: string;
  pacientIndividualIdentifier: string;
  pacientName: string;
  physicianNickName: string;
  professionalCouncilNumber: string;
  physicianCodeExecute: string;
  physicianNameExecute: string;
  council: string;
  calendarIdentifier: number;
  calendarName: string;
  calendarShortName: string;
  descriptionSite: string;
  calendarDate: string;
  calendarStartDate: string;
  calendarEndDate: string;
  minutesDuration: number;
  observation: string;
  scheduleShiftCode: string;
  scheduleShiftName: string;
  calendarStatusCode: EcalendarStatusCode;
  calendarStatusName: string;
  establishmentCode: number;
  enableCode: string;
  enableName: string;
  procedureInternalCode: number;
  procedureOriginCode: number;
  procedureName: string;
  procedureTussCode: number;
  procedureTussName: string;
}

export interface IIGetCalendarByDateParams {
  CalendarDateStart: string;
  CalendarDateEnd: string;
  MedicalSpecialtyCode?: string;
  MedicalSpecialtyCodeList?: string[];
  SituationCalendar: string | null;
  CalendarIdentifier?: number;
  CalendarIdentifierList: number[];
  ScheduleShiftCode: string | null;
  TasyScheduleTypeCode?: string;
}

export interface IIGetAllCalendarParams {
  CalendarDateStart: string;
  CalendarDateEnd: string;
  TasyScheduleTypeCode: string | null;
  CalendarIdentifierList: number[];
  SituationCalendar: string | null;
  GroupingIdentifierList: number[];
  ScheduleShiftCodeList: string[];
}

export interface IConsultation {
  physicianNickName: string;
  physicianCodeExecute: string;
  physicianNameExecute: string;
  schedules: ICalendar[];
}

export interface IExam {
  groupingIdentifier: number;
  groupingName: string;
  schedules: ICalendar[];
}

export interface IGetAll {
  consultations: IConsultation[];
  exams: IExam[];
}

export interface ICancelationReason {
  identifier: number;
  establishmentCode: number;
  cancellationReasonCode: string;
  cancellationReasonName: string;
  enableCode: string;
  enableName: string;
}

export interface ICalendarCancellationParams {
  identifier: string;
  identifierCalendar: string;
  calendarDate: string;
  cancellationReasonCode: string;
  cancellationReasonDescription: string;
}

export interface IIScheduleConsultationParams {
  identifier: number;
  pacientIndividualIdentifier: string;
  insuranceCode: string;
}

interface IKinshipDegree {
  id: string;
  name: string;
}

export interface IIndividual {
  id: string;
  name: string;
  document: string;
  birthDate: string;
  integrationId: string;
  country: string | null;
  gender: ISelectType | null;
}
export interface IFamilyMember {
  id: string | null;
  individual: IIndividual;
  kinshipDegree: IKinshipDegree | null;
}

interface IBasicAvaiableDates {
  MonthYear: string;
  ScheduleShiftCode?: string | null;
  MinDate: string;
  CalendarIdentifier?: string;
}
export interface IGetConsultationAvaiableDatesParams
  extends IBasicAvaiableDates {
  MedicalSpecialtyCode?: string;
}

export interface IExamAvaiableDatesParams extends IBasicAvaiableDates {
  GroupingIdentifier: number;
  MinDate: string;
}
export interface IGetExamAvaiableScheduleParams
  extends Omit<IExamAvaiableDatesParams, "MinDate"> {
  Date: string;
}

export interface IGetConsultationAvaiableScheduleParams
  extends Omit<IGetConsultationAvaiableDatesParams, "MinDate"> {
  Date: string;
}

export interface ISchedule {
  identifier: number;
  pacientName: string;
  calendarIdentifier: number;
  descriptionSite: string;
  calendarDate: string;
  calendarStartDate: string;
  tasySchedulingType: ISelectType;
}

export interface IAvaiableDates {
  dates: string[];
}

export interface IAvaiableSchedule {
  identifier: number;
  calendarName: string;
  calendarShortName?: string;
  descriptionSite: string;
  schedules: ISchedule[];
}

export interface IIScheduleConsultationParams {
  identifier: number;
  pacientIndividualIdentifier: string;
  insuranceCode: string;
}

export interface IScheduleProps {
  data: ISchedule;
}

export interface ITasySchedCheckInRule {
  id: string;
  metersApproximation: number;
  time: number;
}
