import { FormContainer, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import Fields from "./Fields";

import {
  createChannelClassification,
  findChannelClassificationById,
  updateChannelClassification,
} from "../../../../../services/salesChannelClassification.service";
import { buildTPath } from "../channel-classifications.utils";
import {
  channelClassificationSchema,
  TChannelClassificationForm,
} from "./channelClassification.schema";

const ChannelClassification = () => {
  const { t } = useTranslation();

  const { sendRequest: create, loading: isSaving } = useFetch(
    createChannelClassification
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    updateChannelClassification
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    findChannelClassificationById
  );

  const handleFormValues = ({ ...rest }: TChannelClassificationForm) => ({
    ...rest,
  });

  const createHandler = (values: TChannelClassificationForm) =>
    create(handleFormValues(values));

  const updateHandler = (values: TChannelClassificationForm, id: string) =>
    update({ id, item: handleFormValues({ ...values, id }) });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TChannelClassificationForm>
      saveFunction={createHandler}
      updateFunction={updateHandler}
      getFunction={findById}
      loading={isLoading}
      title={t(buildTPath("channel_classification"))}
      schema={channelClassificationSchema}
      subtitle={t(buildTPath("new"))}
      fields={(type) => <Fields type={type} />}
    />
  );
};

export default ChannelClassification;
