import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Modal, Table, useFetch } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import {
  GridCheckCircleIcon,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";

import { memo, useCallback, useMemo, useState } from "react";
import MainApi from "../../../../services/mainApi.service";
import { ISalesContractPriceList } from "../../../../models/sales-contract-price-list";
import { approveSalesContractPriceList } from "../../../../services/salesContractPriceList.service";
import { SalesContractPriceList } from "./SalesContractPriceListForm/SalesContractPriceList";
const translationsPath =
  "page.4uSalesContract.registers.salesContractPriceList.";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractPriceList/";
const service = new MainApi<ISalesContractPriceList>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractPriceList>;

export const SalesContractPriceListPage = memo(() => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<string | null>(null);

  const [refresh, setRefresh] = useState(0);

  const { sendRequest: approve } = useFetch(approveSalesContractPriceList);

  const { id: salesContractProductId } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const translate = (field: string) => t(translationsPath + field);

  const columns: GridColDef<ISalesContractPriceList>[] = [
    {
      field: "name",
      headerName: translate("name"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "code",
      headerName: translate("code"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "initialDate",
      headerName: translate("initial_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "endDate",
      headerName: translate("end_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "approvalDate",
      headerName: translate("approval_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
  ];

  const handleAdd = () => {
    setOpen(true);
  };

  const handleRowClick = (params: GridRowParams<ISalesContractPriceList>) => {
    setId(params.id.toString());
    setOpen(true);
  };

  const onApproveProductPriceList = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
      }
    },
    [approve]
  );
  const closeHandler = () => {
    setId(null);
    setOpen(false);
  };

  const successHandler = () => {
    setRefresh((prevState) => prevState + 1);
    closeHandler();
  };
  const props = useMemo(() => {
    return {
      refresh: refresh > 0 ? refresh : undefined,
      salesContractProductId: salesContractProductId,
    };
  }, [salesContractProductId, refresh]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Modal onClose={closeHandler} open={open}>
        <SalesContractPriceList
          id={id}
          onSuccess={successHandler}
          onCancel={closeHandler}
        />
      </Modal>
      <Box height={600}>
        <Table<ISalesContractPriceList>
          searchInput
          showDefaultMacroFilters={false}
          columns={columns}
          service={service}
          onAdd={handleAdd}
          onRowClick={handleRowClick}
          backButton
          serviceProps={props}
          title={translate("title")}
          defaultPageSize={20}
          pageSizeOptions={[5, 10, 20]}
          addButtonLabel={translate("add")}
          searchInputPlaceHolder={translate("search")}
          rowActions={(params) => [
            params.row.approvalDate === null
              ? {
                  action: (params) => {
                    onApproveProductPriceList(params.row.id);
                  },
                  icon: <GridCheckCircleIcon fontSize="small" />,
                  text: `${t(translationsPath + "approve")}`,
                }
              : null,
          ]}
          confirmOptions={{
            title: translate("confirm.title"),
            description: translate("confirm.description"),
            confirmationButtonProps: { variant: "contained" },
          }}
        />
      </Box>
    </Box>
  );
});
