import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  DateInput,
  FormContainer,
  FormInput,
  IInputFile,
  InputFileForm,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { listContractAttachmentType } from "../../../../../services/contractAttachmentType.service";
import {
  salesContractAttachmentSchema,
  TSalesContractAttachmentForm,
} from "./salesContractAttachment.schema";
import {
  createSalesContractAttachment,
  getSalesContractAttachmentById,
  updateSalesContractAttachmentById,
} from "../../../../../services/salesContractAttachment.service";
import { ISalesContractProps } from "./models";
import { GlobalAxios } from "../../../../../axios/axios-config";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

const translationPath =
  "page.4uSalesContract.registers.salesContractAttachments.";

export const SalesContractAttachment = memo(({ id }: ISalesContractProps) => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();
  let deleteFilesList: string[] = [];

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractAttachment
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateSalesContractAttachmentById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getSalesContractAttachmentById
  );

  const updateHandler = (v: TSalesContractAttachmentForm, id: string) => {
    return update({
      item: {
        id: id,
        title: v.title,
        description: v.description,
        salesContractId: salesContractId ?? "",
        attachmentDate: v.attachmentDate.toISOString().substring(0, 10),
        salesContractAttachmentTypeId: v.salesContractAttachmentType
          ? v.salesContractAttachmentType.id
          : "",
        mediaFileId: v.mediaFile.id,
        deleteMediaFilesIds: deleteFilesList,
      },
      id: id,
    });
  };

  const saveHandler = (v: TSalesContractAttachmentForm) =>
    save({
      title: v.title,
      description: v.description,
      salesContractId: salesContractId ?? "",
      attachmentDate: v.attachmentDate.toISOString().substring(0, 10),
      salesContractAttachmentTypeId: v.salesContractAttachmentType
        ? v.salesContractAttachmentType.id
        : "",
      mediaFileId: v.mediaFile.id,
      deleteMediaFilesIds: deleteFilesList,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesContractAttachmentForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TSalesContractAttachmentForm = {
          ...data,
          title: data.title,
          description: data.description,
          salesContractAttachmentType: {
            ...data.salesContractAttachmentType,
            code: Number(data.salesContractAttachmentType.code),
          },
          attachmentDate: new Date(`${data.attachmentDate}T00:00:00`),
          mediaFile: {
            cdnDomain: data.cdnDomain,
            id: data.id,
            mediaTypeId: data.mediaTypeId,
            isPublic: data.isPublic,
            fileName: data.fileName,
            filePath: data.filePath,
            mimeType: data.mimeType,
            fileSize: data.fileSize,
            mediaType: data.mediaType,
          },
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TSalesContractAttachmentForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "title")}
      schema={salesContractAttachmentSchema}
      subtitle={t(translationPath + "new_attachment")}
      subtitleWatchField={"title"}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="title"
              fullWidth
              label={t(translationPath + "title_form") || "Title"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              multiline
              name="description"
              fullWidth
              minRows={4}
              label={t(translationPath + "description") || "Description"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <DateInput
              name={"attachmentDate"}
              label={
                t(translationPath + "attachment_date") || "Attachment Date"
              }
              initialDate={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "attachment_type")}
              name="salesContractAttachmentType"
              request={listContractAttachmentType}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputFileForm
              name={"mediaFile"}
              axios={GlobalAxios}
              disable={!id ? false : true}
              fileRoute={FILE_ROUTE}
              fileInputProps={{
                accept: ".jpg, ,jpeg, .png, .webp",
                label: `${t("page.register.extensions.file")}`,
                maxFileSize: 2,
                mediaTypeCode: "1",
                mediaTypeIdentifier: "SalesContractAttachmentMediaType",
                parentName: "SalesContractAttachment",
                type: "Public",
                onFileSelected: onDeleFileHandler,
                inputProps: {
                  size: "small",
                },
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  );
});
