import { Box } from "@mui/material";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IIndividualValidation } from "../../../../../models/individual-validations";
import { Data } from "../Data";
import { Status } from "../Status/Status";

const TRANSLATION_PATH = "page.register.individualValidation.";

export const DataList = memo((props: IIndividualValidation) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    individual,
    approvalUser,
    approvalDate,
    disapprovalUser,
    disapprovalDate,
    status,
    email,
    whatsappNumber,
  } = props;

  const formatDate = useCallback(
    (date: string, showTime?: boolean) => {
      const dateFormat = showTime || showTime === undefined ? "Pp" : "P";

      const initialDate = new Date(date);
      return format(initialDate, dateFormat, {
        locale: language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
      }).replaceAll(",", "");
    },
    [language]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 5 }}
      >
        <Data label={t(`${TRANSLATION_PATH}name`)} value={individual.name} />
        <Data
          label={t(`${TRANSLATION_PATH}document`)}
          value={individual.document}
        />
        <Data
          label={t(`${TRANSLATION_PATH}birth_date`)}
          value={formatDate(`${individual.birthDate}T15:00:00Z`, false)}
        />
        <Data label={t(`${TRANSLATION_PATH}email`)} value={email} />
        <Data
          label={t(`${TRANSLATION_PATH}whatsapp_number`)}
          value={whatsappNumber}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
        <Status statusCode={status.code ?? ""} />
        {approvalUser && (
          <Data
            label={t(`${TRANSLATION_PATH}approved_by`)}
            value={approvalUser.fullName}
            canCopy={false}
          />
        )}
        {approvalDate && (
          <Data
            label={t(`${TRANSLATION_PATH}approved_at`)}
            value={formatDate(approvalDate)}
            canCopy={false}
          />
        )}
        {disapprovalUser && (
          <Data
            label={t(`${TRANSLATION_PATH}disapproved_by`)}
            value={disapprovalUser.fullName}
            canCopy={false}
          />
        )}
        {disapprovalDate && (
          <Data
            label={t(`${TRANSLATION_PATH}disapproved_at`)}
            value={formatDate(disapprovalDate)}
            canCopy={false}
          />
        )}
      </Box>
    </Box>
  );
});
