import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { Grid } from "@mui/material";
import { memo } from "react";
import { ISalesContractSubStipulant } from "../../../../../models/sales-contract-sub-stipulant";

const translationPath =
  "page.4uSalesContract.registers.salesContractSubStipulant.";

export const SalesContractSubStipulantInformation = memo(
  ({ data }: { data: ISalesContractSubStipulant }) => {
    const { t } = useTranslation();
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "situation")}
            data={data.situation.name?.toString() ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "code")}
            data={data.code ? data.code.toString() : ""}
          />
        </Grid>
        {data.individual ? (
          <Grid item xs={12} sm={12}>
            <FormInformation
              label={t(translationPath + "individual_person")}
              data={data.individual?.individualName || ""}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12}>
            <FormInformation
              label={t(translationPath + "legal_entity")}
              data={data.legalEntity?.corporateName || ""}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <FormInformation
            label={t(translationPath + "integration_code")}
            data={data?.integrationId ?? "-"}
          />
        </Grid>
      </>
    );
  }
);
