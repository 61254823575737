import { useTranslation } from "react-i18next";

import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import Fields from "./Fields";

import { useCallback } from "react";
import {
  createContractProductClassification,
  findContractProductClassificationById,
  updateContractProductClassification,
} from "../../../../../services/salesProductCtrClassification.service";
import { buildTPath } from "../contract-produc-classification.utils";
import {
  contractProductClassificationSchema,
  TContractProductClassificationForm,
} from "./contractProductClassification.schema";

const ContractProductClassification = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: isSaving } = useFetch(
    createContractProductClassification
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    updateContractProductClassification
  );
  const { sendRequest: get, loading: isFetching } = useFetch(
    findContractProductClassificationById
  );

  const saveHandler = (v: TContractProductClassificationForm) =>
    save({
      name: v.name,
      enable: v.enable,
    });

  const updateHandler = (v: TContractProductClassificationForm, id: string) =>
    update({
      item: {
        ...v,
        name: v.name,
        enable: v.enable,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (
      id: string
    ): Promise<Treturn<TContractProductClassificationForm>> => {
      const { data, success } = await get(id);
      if (data) {
        const newData: TContractProductClassificationForm = {
          ...data,
          name: data.name,
          enable: data.enable,
          code: data.code,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TContractProductClassificationForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={isLoading}
      title={t(buildTPath("contract_product_classification"))}
      schema={contractProductClassificationSchema}
      subtitle={t(buildTPath("new"))}
      subtitleWatchField="name"
      fields={() => <Fields />}
    />
  );
};

export default ContractProductClassification;
