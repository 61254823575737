import {
  Button,
  Modal,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { updateApproveStatus } from "../../../../../../services/individualValidation.service";
import { IDisapproveModalProps } from "./models";

const TRANSLATION_PATH =
  "page.register.individualValidation.actions.disapprove_modal.";

export const DisapproveModal = memo(
  ({
    id,
    isApproving,
    loading,
    onNext,
    onDisapproving,
  }: IDisapproveModalProps) => {
    const { t } = useTranslation();

    const { setMessage } = useNotificationContext();

    const { id: currentId } = useParams();

    const { sendRequest, loading: dLoading } = useFetch(updateApproveStatus);

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const disapproveHandler = useCallback(async () => {
      const { data, success } = await sendRequest({ id, approve: false });
      if (data && success) {
        setMessage({
          message: t("page.register.individualValidation.actions.disapproved"),
          type: "success",
        });
        if (currentId) {
          return navigate(-1);
        }
        onNext();
      }
    }, [sendRequest, setMessage, onNext, navigate, t, currentId, id]);

    const openHandler = useCallback(() => {
      setOpen(true);
    }, []);

    const closeHandler = useCallback(() => {
      setOpen(false);
    }, []);

    useEffect(() => {
      onDisapproving(dLoading);
    }, [onDisapproving, dLoading]);

    return (
      <>
        <Modal
          open={open}
          onClose={closeHandler}
          title={`${t(`${TRANSLATION_PATH}title`)}`}
        >
          <Typography
            sx={(t) => ({
              color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
            })}
          >
            {t(`${TRANSLATION_PATH}description`)}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              gap: 1,
            }}
          >
            <Button variant="outlined" onClick={closeHandler}>
              {t(`${TRANSLATION_PATH}cancel`)}
            </Button>
            <Button
              variant="contained"
              loading={!isApproving && (loading || dLoading)}
              onClick={disapproveHandler}
            >
              {t(`${TRANSLATION_PATH}confirm`)}
            </Button>
          </Box>
        </Modal>
        <Button
          color="error"
          variant="outlined"
          disabled={loading}
          startIcon={<CloseRoundedIcon />}
          onClick={openHandler}
        >
          {t("page.register.individualValidation.actions.disapprove")}
        </Button>
      </>
    );
  }
);
