import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  TTicketAutomationInternalTemplateCategoryForm,
  ticketAutomationInternalTemplateCategorySchema,
} from "./ticketAutomationInternalTemplateCategorySchema";
import {
  createCategoriesInternalTemplates,
  getCategoriesInternalTemplatesById,
  updateCategoriesInternalTemplatesById,
} from "../../../../../../services/ticketAutomationInternalTemplateCategory.service";
import { useCallback } from "react";
import { ICategoriesProps } from "./models";
const translationPath = "chatBot.page.templates.internalCrm.";

const Categories = ({ id, onBackButton }: ICategoriesProps) => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createCategoriesInternalTemplates
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateCategoriesInternalTemplatesById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getCategoriesInternalTemplatesById
  );

  const updateHandler = (
    v: TTicketAutomationInternalTemplateCategoryForm,
    id: string
  ) =>
    update({
      item: {
        id: id,
        name: v.name,
        enable: v.enable,
      },
      id: id,
    });

  const saveHandler = (v: TTicketAutomationInternalTemplateCategoryForm) =>
    save({
      name: v.name,
      enable: v.enable,
    });

  const getHandler = useCallback(
    async (
      id: string
    ): Promise<Treturn<TTicketAutomationInternalTemplateCategoryForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TTicketAutomationInternalTemplateCategoryForm = {
          ...data,
          name: data.name,
          enable: data.enable,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTicketAutomationInternalTemplateCategoryForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "categories")}
      schema={ticketAutomationInternalTemplateCategorySchema}
      subtitle={t(translationPath + "new_category")}
      subtitleWatchField={"name"}
      backButtonOnClick={onBackButton}
      redirectOnSave={false}
      onSuccess={onBackButton}
      isModalForm={true}
      propId={id}
      formActionsOnBottom={false}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign="end">
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              name="name"
              size="small"
              fullWidth
              label={t(translationPath + "name")}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Categories;
