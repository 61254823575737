import { FormInput } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import EntitySelect from "./EntitySelect/EntitySelect";
import { ISalesContractSubStipulant } from "../../../../../models/sales-contract-sub-stipulant";

const translationPath =
  "page.4uSalesContract.registers.salesContractSubStipulant.";

export const SalesContractSubStipulantForm = memo(
  ({
    type,
    dataSalesContractSubStipulant,
  }: {
    type: string;
    dataSalesContractSubStipulant: ISalesContractSubStipulant | undefined;
  }) => {
    const { t } = useTranslation();
    return (
      <>
        {type !== "create" && dataSalesContractSubStipulant?.situation && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "situation")}
              data={
                dataSalesContractSubStipulant.situation.name?.toString() ?? ""
              }
            />
          </Grid>
        )}
        {type !== "create" && dataSalesContractSubStipulant?.code && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "code")}
              data={dataSalesContractSubStipulant.code.toString()}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <EntitySelect />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            name="integrationId"
            fullWidth
            label={t(translationPath + "integration_code") || "Integration"}
          />
        </Grid>
      </>
    );
  }
);
