import { useMemo } from "react";
import { IInstalledExtension } from "../models/intalled-extensions";
import { useAppSelector } from "../store/store";

export const useInstalledExtensions = (
  extensionCode?: string
): [boolean, IInstalledExtension[]] => {
  const installedExtensions = useAppSelector(
    (state) => state.installedExtensions.extensions
  );

  const hasExtension = useMemo(
    () =>
      !!installedExtensions.find(
        (extension) => extension.code === extensionCode
      ),
    [installedExtensions, extensionCode]
  );

  return [hasExtension, installedExtensions];
};
