import { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { IContracProductProps } from "../../ContractProductHistories/ContractProductHistoryForm/models";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { useTranslation } from "react-i18next";
import { FormContainer, FormInput, Treturn, useFetch } from "@4uhub/lib4uhub";
import {
  createSaleSeller,
  findSaleSellerById,
  updateSaleSeller,
} from "../../../../../services/salesSeller.service";
import { Grid } from "@mui/material";
import { salesSellerSchema, TSalesSellerForm } from "./salesSeller.schema";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { ISaleSeller } from "../../../../../models/sales-seller";
import { SalesSellerIndividual } from "./SalesSellerIndividual/SalesSellerIndividual";

const translationPath = "page.4uSalesContract.registers.salesSeller.";

export const SalesSeller = memo(({ id }: IContracProductProps) => {
  const { id: salesChannelId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();

  const [data, setData] = useState<ISaleSeller>();

  const { sendRequest: save, loading: sLoading } = useFetch(createSaleSeller);

  const { sendRequest: update, loading: uLoading } = useFetch(updateSaleSeller);

  const { sendRequest: get, loading: gLoading } = useFetch(findSaleSellerById);

  const updateHandler = (v: TSalesSellerForm, id: string) => {
    return update({
      item: {
        id: id,
        titleSeller: v.titleSeller,
        salesChannelId: salesChannelId ?? "",
        individualId: v.individual?.id ?? "",
      },
      id: id,
    });
  };

  const saveHandler = (v: TSalesSellerForm) =>
    save({
      titleSeller: v.titleSeller,
      salesChannelId: salesChannelId ?? "",
      individualId: v.individual?.id ?? "",
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesSellerForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setData(data);
        const newData: TSalesSellerForm = {
          ...data,
          titleSeller: data.titleSeller,
          situation: data.situation,
          individual: {
            document: data.individual.code!,
            id: data.individual.id,
            individualName: data.individual.name,
          },
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TSalesSellerForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "title")}
      schema={salesSellerSchema}
      subtitle={t(translationPath + "new")}
      subtitleWatchField={"titleSeller"}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          {data && type !== "create" && data.situation && (
            <Grid item xs={12} sm={12}>
              <FormInformation
                label={t(translationPath + "situation")}
                data={data.situation.name?.toString() ?? ""}
                minHeight={40}
              />
            </Grid>
          )}
          <SalesSellerIndividual />
          <Grid item xs={12} sm={12}>
            <FormInput
              name="titleSeller"
              label={t(translationPath + "title_seller") || "titleSeller"}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
});
