import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import documentsReducer from "./slices/documents";
import installedExtensionsReducer from "./slices/installedExtensions";
import menuReducer from "./slices/menu";
import settingsGeneralReducer from "./slices/settings-general";

const combinedReducer = combineReducers({
  menu: menuReducer,
  documents: documentsReducer,
  settingsGeneral: settingsGeneralReducer,
  installedExtensions: installedExtensionsReducer,
});

const store = configureStore({
  reducer: combinedReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
