import { Button, UsePrivileges } from "@4uhub/lib4uhub";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { IInstalledButtonProps } from "./models";

const translationPath = "components.extensionsCard.";

export const InstallationButton = memo(
  ({
    isInstalled,
    isRequiredSetup,
    wasRequested,
    onOpenDetail,
    onOpenInstall,
  }: IInstalledButtonProps) => {
    const { t } = useTranslation();

    const { privileges } = UsePrivileges();

    const isMaster = privileges.find(({ name }) => name === "platform-all");

    return (
      <Button
        variant={"outlined"}
        size="small"
        sx={{
          width: "fit-content",
          mb: 2,
          cursor: wasRequested ? "default" : "pointer",
        }}
        startIcon={
          wasRequested ? (
            <CheckCircleRoundedIcon />
          ) : isInstalled ? (
            <InfoRoundedIcon />
          ) : isRequiredSetup && !isMaster ? (
            <SendTimeExtensionIcon />
          ) : (
            <DownloadRoundedIcon />
          )
        }
        onClick={
          wasRequested
            ? undefined
            : isInstalled
            ? onOpenDetail
            : (event) => {
                event.stopPropagation();
                onOpenInstall();
              }
        }
      >
        {wasRequested && t(translationPath + "requested")}
        {isInstalled && t(translationPath + "details")}
        {!isInstalled &&
          (isMaster || !isRequiredSetup) &&
          t(translationPath + "install")}
        {!isInstalled &&
          !isMaster &&
          isRequiredSetup &&
          t(translationPath + "request_install")}
      </Button>
    );
  }
);
