import { Box } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { memo, useCallback, useMemo } from "react";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";
import { Table, useFetch } from "@4uhub/lib4uhub";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";
import MainApi from "../../../../services/mainApi.service";
import { ISalesContractSubStipulant } from "../../../../models/sales-contract-sub-stipulant";
import SituationChip from "../../Register/SalesChannel/SituationChip";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import {
  approveSalesContractSubStipulant,
  inactivateSalesContractSubStipulant,
  reactivateSalesContractSubStipulant,
  suspendSalesContractSubStipulant,
} from "../../../../services/salesContractSubStipulant.service";

const translationPath =
  "page.4uSalesContract.registers.salesContractSubStipulant.";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractSubStipulant/";
const service = new MainApi<ISalesContractSubStipulant>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractSubStipulant>;

export const SalesContractSubStipulantPage = memo(() => {
  const { onOpen, selectPropId, onRefresh, selectTabName, refresh } =
    useSalesContract();

  const { sendRequest: approve } = useFetch(approveSalesContractSubStipulant);
  const { sendRequest: inactivate } = useFetch(
    inactivateSalesContractSubStipulant
  );
  const { sendRequest: reactivate } = useFetch(
    reactivateSalesContractSubStipulant
  );
  const { sendRequest: suspend } = useFetch(suspendSalesContractSubStipulant);

  const { id } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const translate = (field: string) => t(translationPath + field);

  const columns: GridColDef<ISalesContractSubStipulant>[] = [
    {
      field: "code",
      headerName: translate("code"),
    },
    {
      field: "reactivationDate",
      headerName: translate("reactivation_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "inactiveDate",
      headerName: translate("inactivation_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "suspensionDate",
      headerName: translate("suspension_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "approvalDate",
      headerName: translate("approval_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "situation",
      maxWidth: 100,
      headerName: translate("situation"),
      renderCell: ({ value }) => (
        <SituationChip name={value?.name} code={value?.code} />
      ),
    },
  ];

  const handleAdd = () => {
    onOpen();
    selectTabName("substipulant");
  };

  const rowClickHandler = useCallback(
    (params: GridRowParams<ISalesContractSubStipulant>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("substipulant");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const onApproveSalesContractSubStipulant = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        onRefresh();
      }
    },
    [approve, onRefresh]
  );
  const onInactivateSalesContractSubStipulant = useCallback(
    async (id: string) => {
      const { success } = await inactivate(id);
      if (success) {
        onRefresh();
      }
    },
    [inactivate, onRefresh]
  );

  const onReactivationSalesContractSubStipulant = useCallback(
    async (id: string) => {
      const { success } = await reactivate(id);
      if (success) {
        onRefresh();
      }
    },
    [reactivate, onRefresh]
  );

  const onSuspensionSalesContractSubStipulant = useCallback(
    async (id: string) => {
      const { success } = await suspend(id);
      if (success) {
        onRefresh();
      }
    },
    [suspend, onRefresh]
  );

  const props = useMemo(() => {
    return { SalesContractId: id, refresh };
  }, [id, refresh]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Box height={600}>
        <Table<ISalesContractSubStipulant>
          searchInput={false}
          showDefaultMacroFilters={false}
          columns={columns}
          service={service}
          onAdd={handleAdd}
          onRowClick={rowClickHandler}
          serviceProps={props}
          title={translate("title")}
          defaultPageSize={20}
          pageSizeOptions={[5, 10, 20]}
          addButtonLabel={translate("add")}
          searchInputPlaceHolder={translate("search")}
          rowActions={(params) => [
            params.row.situation.code === "1" &&
            params.row.approvalDate === null
              ? {
                  action: (params) => {
                    onApproveSalesContractSubStipulant(params.row.id);
                  },
                  icon: <CheckCircleIcon fontSize="small" />,
                  text: `${t(translationPath + "approve")}`,
                }
              : null,
            params.row.inactiveDate === null &&
            params.row.situation.code === "1"
              ? {
                  action: (params) => {
                    onInactivateSalesContractSubStipulant(params.row.id);
                  },
                  icon: <DoDisturbOnIcon fontSize="small" />,
                  text: `${t(translationPath + "inactivation")}`,
                }
              : null,
            params.row.situation.code === "3" &&
            params.row.reactivationDate == null
              ? {
                  action: (params) => {
                    onReactivationSalesContractSubStipulant(params.row.id);
                  },
                  icon: <PlayCircleFilledIcon fontSize="small" />,
                  text: `${t(translationPath + "reactivation")}`,
                }
              : null,
            params.row.suspensionDate === null &&
            params.row.situation.code === "1"
              ? {
                  action: (params) => {
                    onSuspensionSalesContractSubStipulant(params.row.id);
                  },
                  icon: <PauseCircleFilledIcon fontSize="small" />,
                  text: `${t(translationPath + "suspension")}`,
                }
              : null,
          ]}
          confirmOptions={{
            title: translate("confirm.title"),
            description: translate("confirm.description"),
            confirmationButtonProps: { variant: "contained" },
          }}
        />
      </Box>
    </Box>
  );
});
