import { z } from "zod";

const translationPath = "chatBot.page.templates.internalCrm.errors.";

export const ticketAutomationInternalTemplateVariableSchema = z.object({
  id: z.string(),
  code: z.string().optional(),
  name: z.string().optional(),
});

const fieldsSchema = z
  .object({
    id: z.string().optional(),
    ticketAutomationInternalTemplateVariable:
      ticketAutomationInternalTemplateVariableSchema.nullish(),
    label: z.string().min(1, translationPath + "label"),
    screenOrder: z.string().min(1, translationPath + "screen_order"),
    isRequired: z.boolean(),
  })
  .superRefine((val, ctx) => {
    if (!val.ticketAutomationInternalTemplateVariable) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["ticketAutomationInternalTemplateVariable"],
        message: translationPath + "variable",
      });
    }
  });

export const ticketAutomationInternalTemplateTemplateSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  fields: z.array(fieldsSchema).min(1, translationPath + "fields"),
});

export type TTicketAutomationInternalTemplateTemplateForm = z.infer<
  typeof ticketAutomationInternalTemplateTemplateSchema
>;
