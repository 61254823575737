import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormArray,
  FormInput,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo } from "react";
import MainApi from "../../../../../../services/mainApi.service";
import { TSalesContractPriceListForm } from "../salesContractPriceListSchema";

const translationPath =
  "page.4uSalesContract.registers.salesProductCtrPriceList.";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

export const SalesContractPriceListItems = memo(() => {
  const { control, formState } = useFormContext<TSalesContractPriceListForm>();
  const { t } = useTranslation();

  const documentsFieldArray = useFieldArray<TSalesContractPriceListForm>({
    control,
    name: "salesContractPriceListItems",
  });

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "items")}
        name="salesContractPriceListItems"
        addButtonLabel={t(translationPath + "add_new_item") || "Add new item"}
        formArray={documentsFieldArray}
        appendValue={{
          price: "",
          endAge: "",
          initalAge: "",
        }}
        fieldsObject={(index) => {
          const initalAge = `salesContractPriceListItems.${index}.initalAge`;
          const endAge = `salesContractPriceListItems.${index}.endAge`;
          const price = `salesContractPriceListItems.${index}.price`;
          const degreeOfEntitlement = `salesContractPriceListItems.${index}.degreeOfEntitlement`;

          return (
            <>
              <Grid item xs={12} sm={6}>
                <AutoComplete
                  params={{ identifier: "DegreeOfEntitlement" }}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  label={t(translationPath + "degree_of_entitlement")}
                  name={degreeOfEntitlement}
                  request={genericService.list}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInput
                  size="small"
                  type="number"
                  name={price}
                  label={t(translationPath + "price") || "Price"}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInput
                  size="small"
                  type="number"
                  name={initalAge}
                  label={t(translationPath + "initial_age") || "Inital Age"}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormInput
                  size="small"
                  type="number"
                  name={endAge}
                  label={t(translationPath + "end_age") || "End Age"}
                  fullWidth
                />
              </Grid>
            </>
          );
        }}
      />
      {formState.errors.salesContractPriceListItems && (
        <Typography variant="body2" color={"error"}>
          {formState.errors.salesContractPriceListItems.message
            ? t(`${formState.errors.salesContractPriceListItems.message}`)
            : formState.errors.salesContractPriceListItems.root?.message
            ? t(`${formState.errors.salesContractPriceListItems.root?.message}`)
            : null}
        </Typography>
      )}
    </Grid>
  );
});
