import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { IGetIndividual, IIndividuals } from "../models/individuals";
import { ISearchParams, ISearchReturn } from "../models/requests";

const BASE_URL = process.env.REACT_APP_MAIN;

const INDIVIDUAL_ROUTE = BASE_URL + "/api/v1/Individual/";

export class IndividualsService {
  create = (item: IIndividuals) => {
    return GlobalAxios.post(INDIVIDUAL_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<any>>(INDIVIDUAL_ROUTE, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetIndividual>(INDIVIDUAL_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: IIndividuals; id: string }) => {
    return GlobalAxios.put(INDIVIDUAL_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(INDIVIDUAL_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(INDIVIDUAL_ROUTE + "list", {
      params: params,
    });
  };

  deleteAddress = (id: string) => {
    return GlobalAxios.delete(INDIVIDUAL_ROUTE + "Address/" + id);
  };
}

export default IndividualsService;
