import { FormTabs } from "@4uhub/lib4uhub";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CategoriesPage } from "./Categories/CategoriesPage";
import { TemplatePage } from "./Template/TemplatePage";
import { VariablesPage } from "./Variables/VariablesPage";

const translationPath = "chatBot.page.templates.internalCrm.";

const InternalTemplatesPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack height={"100%"}>
      <Typography variant="h5" mb={2}>
        {t(translationPath + "internal_crm")}
      </Typography>
      <FormTabs
        sx={{ height: matches ? "calc(100vh - 8rem)" : "calc(100vh - 4rem)" }}
        tabs={[
          {
            label: t(translationPath + "categories"),
            component: <CategoriesPage />,
          },
          {
            label: t(translationPath + "variables"),
            component: <VariablesPage />,
          },
          {
            label: t(translationPath + "templates"),
            component: <TemplatePage />,
          },
        ]}
      />
    </Stack>
  );
};

export default InternalTemplatesPage;
