import { GlobalAxios } from "../axios/axios-config";
import {
  ISalesContractHistory,
  ISalesContractHistoryPayload,
} from "../models/sales-contract-history";

const SALES_CONTRACT_HISTORY_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractHistory/";

export const createSalesContractHistory = (
  item: ISalesContractHistoryPayload
) => {
  return GlobalAxios.post(SALES_CONTRACT_HISTORY_ROUTE, item);
};

export const getSalesContractHistoryById = (id: string) => {
  return GlobalAxios.get<ISalesContractHistory>(
    SALES_CONTRACT_HISTORY_ROUTE + id
  );
};

export const updateSalesContractHistoryById = ({
  item,
  id,
}: {
  item: ISalesContractHistoryPayload;
  id: string;
}) => {
  return GlobalAxios.put(SALES_CONTRACT_HISTORY_ROUTE + id, item);
};
export const approveSalesContractHistory = (id: string) => {
  return GlobalAxios.put(`${SALES_CONTRACT_HISTORY_ROUTE}ApprovalDate/${id}`);
};
