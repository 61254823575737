import { Grid } from "@mui/material";
import { memo } from "react";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { ISalesContractHistory } from "../../../../../models/sales-contract-history";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const translationPath =
  "page.4uSalesContract.registers.salesContractHistories.";

export const SalesContractHistoryInformation = memo(
  ({ data }: { data: ISalesContractHistory }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    return (
      <>
        <Grid item xs={12} sm={12}>
          <FormInformation
            label={t(translationPath + "titleForm")}
            data={data?.title ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInformation
            label={t(translationPath + "history_date")}
            data={
              data?.historyDate
                ? (() => {
                    const date = new Date(data.historyDate);
                    date.setHours(
                      date.getHours() + date.getTimezoneOffset() / 60
                    );
                    return format(date, "P", {
                      locale:
                        language === "pt-BR"
                          ? ptBR
                          : language === "es-ES"
                          ? es
                          : enUS,
                    });
                  })()
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInformation
            label={t(translationPath + "history")}
            data={data?.history ?? ""}
          />
        </Grid>
      </>
    );
  }
);
